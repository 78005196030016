import {BaseModel} from './base.model';

export class Product implements BaseModel {
  id: string;
  sku: string;
  shortText: string;
  feature: number;
  unit: string;
  price: number;
  longText: string;
  imageUrl: string;
}
