import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {BuildingTypeService} from '../services/building-type.service';
import {BuildingTypeActionTypes} from '../actions/building-type.actions';

@Injectable()
export class BuildingTypeEffects {
  @Effect()
  loadBuildingTypes$ = this.actions$
    .pipe(
      ofType(BuildingTypeActionTypes.ALL_LOAD),
      mergeMap(() => this.buildingTypeService.all()
        .pipe(
          map(buildingTypes => ({type: BuildingTypeActionTypes.ALL_LOADED, payload: buildingTypes})),
          catchError(() => EMPTY)
        )
      )
    );
  @Effect()
  loadBuildingType$ = this.actions$
    .pipe(
      ofType(BuildingTypeActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.buildingTypeService.get((action as any).payload)
        .pipe(
          map(buildingType => ({type: BuildingTypeActionTypes.SINGLE_LOADED, payload: buildingType})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createBuildingType$ = this.actions$
    .pipe(
      ofType(BuildingTypeActionTypes.CREATE_SEND),
      mergeMap((action) => this.buildingTypeService.create((action as any).payload)
        .pipe(
          map(buildingType => ({type: BuildingTypeActionTypes.CREATE_DONE, payload: buildingType})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updateBuildingType$ = this.actions$
    .pipe(
      ofType(BuildingTypeActionTypes.UPDATE_SEND),
      mergeMap((action) => this.buildingTypeService.update((action as any).payload)
        .pipe(
          map(buildingType => ({type: BuildingTypeActionTypes.UPDATE_DONE, payload: buildingType})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private buildingTypeService: BuildingTypeService
  ) {
  }
}
