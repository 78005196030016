import {Offer} from '../models/offer';
import {OfferActions, OfferActionTypes} from '../actions/offer.actions';

export interface OfferState {
  isLoading: boolean;
  offers: Offer[];
}

export const inititalState: OfferState = {
  isLoading: false,
  offers: []
};

export function reducer(
  state = inititalState,
  action: OfferActions
): OfferState {
  switch (action.type) {
    case OfferActionTypes.ALL_FOR_PROJECT_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case OfferActionTypes.ALL_FOR_PROJECT_LOADED: {
      return {
        ...state,
        isLoading: false,
        offers: action.payload
      };
    }

    case OfferActionTypes.ALL_FOR_PARTNER_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case OfferActionTypes.ALL_FOR_PARTNER_LOADED: {
      return {
        ...state,
        isLoading: false,
        offers: action.payload
      };
    }

    case OfferActionTypes.ALL_FOR_CUSTOMER_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case OfferActionTypes.ALL_FOR_CUSTOMER_LOADED: {
      return {
        ...state,
        isLoading: false,
        offers: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
