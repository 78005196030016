import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {MatTableDataSource} from '@angular/material';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectRoomType} from '../../../models/project-room-type.model';
import {areProjectRoomtTypesLoading, getProjectRoomTypes} from '../../../selectors/project-room-type.selector';
import {ProjectRoomTypesCreate, ProjectRoomTypesLoad} from '../../../actions/project-room-type.actions';

@Component({
  selector: 'project-room-type',
  templateUrl: './project-room-type.component.html',
  styleUrls: ['./project-room-type.component.scss']
})
export class ProjectRoomTypeComponent implements OnInit {

  projectRoomTypes: ProjectRoomType[] = [];
  dataSource = null;
  columnsToDisplay = ['name'];

  projectRoomTypeForm: FormGroup;
  showCreateProjectRoomType = false;

  isLoading$;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.store.pipe(select(getProjectRoomTypes)).subscribe(projectRoomTypes => {
      this.projectRoomTypes = projectRoomTypes;
      this.dataSource = new MatTableDataSource<ProjectRoomType>(projectRoomTypes);
    });

    this.isLoading$ = this.store.pipe(select(areProjectRoomtTypesLoading));
    this.store.dispatch(new ProjectRoomTypesLoad());

    this.projectRoomTypeForm = this.formBuilder.group({
      name: ['', Validators.required],
      nbFloorSensors: [0, Validators.required],
      nbRoomSensors: [0, Validators.required],
      priority: [0, Validators.required]
    });
  }

  createProjectRoomType() {

    const projectRoomType = new ProjectRoomType();
    projectRoomType.name = this.projectRoomTypeForm.get('name').value;
    projectRoomType.nbFloorSensors = this.projectRoomTypeForm.get('nbFloorSensors').value;
    projectRoomType.nbRoomSensors = this.projectRoomTypeForm.get('nbRoomSensors').value;
    projectRoomType.priority = this.projectRoomTypeForm.get('priority').value;

    this.store.dispatch(new ProjectRoomTypesCreate(projectRoomType));
    this.showCreateProjectRoomType = false;
  }

}
