import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {MatTableDataSource} from '@angular/material';
import {Product} from '../../../models/product.model';
import {areProductsLoading, getProducts} from '../../../selectors/product.selector';
import {ProductCreate, ProductsLoad} from '../../../actions/product.actions';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  products: Product[] = [];
  dataSource = null;
  columnsToDisplay = ['shortText'];
  productForm: FormGroup;
  showCreateProduct = false;

  isLoading$;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.store.pipe(select(getProducts)).subscribe(products => {
      if (products) {
        this.products = products;
        this.dataSource = new MatTableDataSource<Product>(products);
      }
    });

    this.isLoading$ = this.store.pipe(select(areProductsLoading));
    this.store.dispatch(new ProductsLoad());

    this.productForm = this.formBuilder.group({
      sku: ['', Validators.required],
      shortText: ['', Validators.required],
      feature: [0, Validators.required],
      unit: ['', Validators.required],
      price: [0, Validators.required],
      longText: ['']
    });
  }

  createProduct() {
    const p: Product = new Product();
    p.sku = this.productForm.get('sku').value;
    p.shortText = this.productForm.get('shortText').value;
    p.feature = this.productForm.get('feature').value;
    p.unit = this.productForm.get('unit').value;
    p.price = this.productForm.get('price').value;
    p.longText = this.productForm.get('longText').value;

    this.store.dispatch(new ProductCreate(p));
    this.showCreateProduct = false;
  }

}
