import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Customer} from '../models/Customer';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Partner} from '../models/Partner';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseService<Customer> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_CUSTOMER);
  }

  allUnassigned(): Observable<Customer[]> {
    return this.http.get<Customer[]>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/allUnassigned');
  }

  assignPartner(customer: Customer, partner: Partner): Observable<Customer> {
    return this.http.post<Customer>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/assignPartner'
      , {customer: customer, partner: partner});
  }
}
