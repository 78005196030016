import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {CustomerCreate, CustomersLoad} from '../../actions/customer.actions';
import {getCustomerStateData, getCustomerStateIsLoading} from '../../selectors/customer.selector';
import {Customer} from '../../models/Customer';
import {Observable} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SetTitle} from '../../actions/ui.actions';
import {getOwnUser} from '../../selectors/user.selector';
import {User} from '../../models/user.model';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CustomersComponent implements OnInit {

  customers: Customer[] = [];
  dataSource = null;
  columnsToDisplay = ['name'];
  expandedElement: Customer | null;
  customerObservable: Observable<Customer[]>;
  isLoading$;
  resultsLength = 0;
  showCreateCustomer = false;
  customerForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: User;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.customerObservable = this.store.pipe(select(getCustomerStateData));
    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;
      this.customerObservable.subscribe(customers => {
        this.customers = customers.filter(c => ((c.partner === this.user.partner || this.user.role == 'admin') && c.hasBeenAssigned));
        this.resultsLength = customers.length;
        this.dataSource = new MatTableDataSource<Customer>(this.customers);
        // TODO: https://blog.angular-university.io/angular-material-data-table/
        setTimeout(() => this.dataSource.paginator = this.paginator, 300);
      });
    });

    this.isLoading$ = this.store.pipe(select(getCustomerStateIsLoading));
    this.store.dispatch(new CustomersLoad());

    this.customerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      companyName: [''],
      uid: [''],
      registerNumber: [''],
      street: [''],
      zipCode: [''],
      city: [''],
      country: ['Österreich'],
      email: [''],
      phone: [''],
      kdrabatt: [''],
      person1_name: [''],
      person1_email: [''],
      person1_phone: [''],
      person2_name: [''],
      person2_email: [''],
      person2_phone: [''],
      person3_name: [''],
      person3_email: [''],
      person3_phone: [''],
    });

    this.store.dispatch(new SetTitle('Kundenübersicht'));
  }

  createCustomer() {

    let c: Customer = new Customer();
    c.partner = this.user.partner;
    c.firstname = this.customerForm.get('firstname').value;
    c.surname = this.customerForm.get('surname').value;
    c.companyName = this.customerForm.get('companyName').value;
    c.street = this.customerForm.get('street').value;
    c.zipCode = this.customerForm.get('zipCode').value;
    c.city = this.customerForm.get('city').value;
    c.country = this.customerForm.get('country').value;
    c.phone = this.customerForm.get('phone').value;
    c.email = this.customerForm.get('email').value;
    c.kdrabatt = this.customerForm.get('kdrabatt').value;

    c.person1_name = this.customerForm.get('person1_name').value;
    c.person1_email = this.customerForm.get('person1_email').value;
    c.person1_phone = this.customerForm.get('person1_phone').value;
    c.person2_name = this.customerForm.get('person2_name').value;
    c.person2_email = this.customerForm.get('person2_email').value;
    c.person2_phone = this.customerForm.get('person2_phone').value;
    c.person3_name = this.customerForm.get('person3_name').value;
    c.person3_email = this.customerForm.get('person3_email').value;
    c.person3_phone = this.customerForm.get('person3_phone').value;

    this.store.dispatch(new CustomerCreate(c));
    this.showCreateCustomer = false;
  }


  // getAddress(c: Customer) {
  //   //return `<span class="street">${c.street}</span>`;//c.street + ', ' + c.zipCode + ' ' + c.city + ', ' + c.country;
  //   return {street: c.street, zipCode: c.zipCode, city: c.city, country: c.country};
  // }


  getProjects(c: Customer) {
    /*let projectString = '';
>>>>>>> 5c0c7144f47d363372a0234990df6973e0c4a778

        c.projects.forEach(p => projectString += (p.name + ', '));
        projectString = projectString.slice(0, -2);

<<<<<<< HEAD
        return projectString;
    }
=======
    return projectString;*/
    return 'gusch';
  }

  showDetails(c: Customer) {
    console.log(c);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // rand() {
  //     const number = Math.floor(Math.random() * Math.floor(5)) + 2;
  //     console.log(number);
  //     return number;
  // }

}
