import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, pipe} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {User} from '../../../../../models/user.model';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {areProjectsLoading, getProducts} from '../../../../../selectors/project.selector';
import {ProjectProduct} from '../../../../../models/project-product.model';
import {map, startWith} from 'rxjs/operators';
import {Project} from '../../../../../models/Project';
import {ProjectProductCreate, ProjectProductDelete, ProjectProductsLoad} from '../../../../../actions/project.actions';
import {Offer} from '../../../../../models/offer';

@Component({
  selector: 'shop-articles',
  templateUrl: './shop-articles.component.html',
  styleUrls: ['./shop-articles.component.scss']
})
export class ShopArticlesComponent implements OnInit {

  shopProducts = [];

  products: ProjectProduct[] = [];
  dataSource = null;
  columnsToDisplay = ['shortText'];
  isLoading$;
  resultsLength = 0;
  showCreateProduct = false;
  productForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: User;
  filteredOptions: Observable<any[]>;

  @Input() project: Project;

  allShopCategoriesSet = new Set();
  shopCategories = [];
  dataSources: { category: string, dataSource: MatTableDataSource<ShopProduct> }[] = [];

  productFilter = new FormControl();

  constructor(private http: HttpClient, private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    const url = 'https://katalog.simplyheat.eu/wp-json/wc/v3/products' +
      '?consumer_key=ck_b87aec8fbaca7a32fa828afa4079235b74a7447e' +
      '&consumer_secret=cs_d8a5fa5ffa843e958e8e4d660c69e9f29870ee7b';
    this.http.get<any>(url, {observe: 'response'}).subscribe(response => {
      response.body.filter(p => p.sku.length > 0).map(p => this.convertToShopProduct(p));
      response.body.filter(p => p.sku.length === 0).map(p => this.getVariations(p));

      const totalPages = +response.headers.get('X-WP-TotalPages');

      if (totalPages > 1) {
        for (let i = 2; i < totalPages + 1; i++) {
          this.http.get<any>(url + '&page=' + i, {observe: 'response'}).subscribe(products => {
            products.body.filter(p => p.sku.length > 0).map(p => this.convertToShopProduct(p));
            products.body.filter(p => p.sku.length === 0).map(p => this.getVariations(p));
          });
        }
      }
    });

    this.store.select(pipe(getProducts)).subscribe(products => {
      this.products = products;
      this.dataSource = new MatTableDataSource<ProjectProduct>(this.products);
      this.resultsLength = this.products.length;
      setTimeout(() => this.dataSource.paginator = this.paginator, 300);
    });

    this.store.dispatch(new ProjectProductsLoad(this.project));

    this.isLoading$ = this.store.select(pipe(areProjectsLoading));

    this.productForm = this.formBuilder.group({
      product: [this.shopProducts[0], Validators.required],
      amount: [0, Validators.required]
    });

    this.filteredOptions = this.productForm.get('product').valueChanges
      .pipe(
        startWith<string | ShopProduct>(''),
        map(value => typeof value === 'string' ? value : value.shortText),
        map(name => name ? this._filter(name) : this.shopProducts.slice())
      );

    this.productFilter.valueChanges.subscribe(value => {

        if(value.length == 0)
        {
            const filteredProducts = this.shopProducts;
            this.dataSources = [];
            this.shopCategories.forEach(c => {
                const elements = filteredProducts;

                if (elements.length > 0) {
                    this.dataSources.push({
                        category: c,
                        dataSource: new MatTableDataSource<ShopProduct>(elements)
                    });
                }


            });
        } else {
            const filteredProducts = this.shopProducts.filter(p => {
                const string = p.shortText + p.longText + p.sku + p.categories.reduce((a, b) => a + b);
                return string.toLowerCase().indexOf(value.toLowerCase()) > 0;
            });

            this.dataSources = [];
            this.shopCategories.forEach(c => {
                const elements = filteredProducts.filter(p => p.categories.indexOf(c) > -1);

                if (elements.length > 0) {
                    this.dataSources.push({
                        category: c,
                        dataSource: new MatTableDataSource<ShopProduct>(elements)
                    });
                }


            });
        }


    });
  }

  addProduct(product: ShopProduct) {
    const p = new ProjectProduct();
    p.id = product.id;
    p.shortText = product.shortText;
    p.imageUrl = product.imageUrl;
    p.price = product.price;
    p.sku = product.sku;
    p.project = this.project.id;
    p.amount = product.amount;
    p.longText = product.longText;

    this.store.dispatch(new ProjectProductCreate(p));

    this.showCreateProduct = false;
  }

  deleteProduct(product: ProjectProduct) {
    this.store.dispatch(new ProjectProductDelete(product));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  displayFn(product?: any): string | undefined {
    return product ? product.shortText : undefined;
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.shopProducts.filter(option => (option.shortText.toLowerCase().indexOf(filterValue) > 0));// && this.products.filter(p => p.id === option.id).length === 0));
  }

  getVariations(data) {
    const url = 'https://katalog.simplyheat.eu/wp-json/wc/v3/products/' + data.id + '/variations' +
      '?consumer_key=ck_b87aec8fbaca7a32fa828afa4079235b74a7447e' +
      '&consumer_secret=cs_d8a5fa5ffa843e958e8e4d660c69e9f29870ee7b';

    this.http.get<any>(url, {observe: 'response'}).subscribe(products => {
      products.body.filter(p => p.sku.length > 0).map(p => this.convertToShopProduct(p, data));
      this.dataSources = [];
      this.shopCategories.forEach(c => {
        this.dataSources.push({
          category: c,
          dataSource: new MatTableDataSource<ShopProduct>(this.shopProducts.filter(p => p.categories.indexOf(c) > -1))
        });
      });
    });
  }

  convertToShopProduct(data, product?) {

    const categories: string[] = [];

    if (product) {
      product.categories.forEach(c => {
        this.allShopCategoriesSet.add(c.name);
        categories.push(c.name);
      });
    } else {
      data.categories.forEach(c => {
        this.allShopCategoriesSet.add(c.name);
        categories.push(c.name);
      });
    }

    this.shopCategories = Array.from(this.allShopCategoriesSet).sort(function (a, b) {
      const textA = a.toUpperCase();
      const textB = b.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

    this.shopProducts.push(new ShopProduct(data.id, product ? product.name + ' - ' + data.attributes[0].option : data.name, data.description, product ? data.image !== undefined ? data.image.src : product.images[0].src : (data.images.length > 0 ? data.images[0].src : ''), data.sku, data.price, categories, 0));
  }
}

export class ShopProduct {
  id: string;
  shortText: string;
  longText: string;
  imageUrl: string;
  sku: string;
  price: number;
  categories: string[];
  amount: number;

  constructor(id, shortText, longText, imageUrl, sku, price, categories, amount) {
    this.id = id;
    this.shortText = shortText;
    this.longText = longText;
    this.imageUrl = imageUrl;
    this.sku = sku;
    this.price = price;
    this.categories = categories;
    this.amount = amount;
  }
}
