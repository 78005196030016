import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ProjectState} from '../reducers/project.reducer';


export const selectState = createFeatureSelector('project');
export const selectProjects = (state: ProjectState) => state.projects;
export const selectProducts = (state: ProjectState) => state.products;
export const selectProjectsIsLoading = (state: ProjectState) => state.isLoading;

export const getProjects = createSelector(selectState, selectProjects);
export const getProducts = createSelector(selectState, selectProducts);
export const areProjectsLoading = createSelector(selectState, selectProjectsIsLoading);
