import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BuildingType} from '../../models/BuildingType';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {getBuildingTypes} from '../../selectors/building-type.selector';
import {MatTableDataSource} from '@angular/material';
import {BuildingTypesLoad} from '../../actions/building-type.actions';
import {Offer} from '../../models/offer';
import {areProjectsLoading, getProjects} from '../../selectors/project.selector';
import {
  OfferCreate,
  OfferLoad,
  OffersLoad,
  OffersForProjectLoad,
  OffersForCustomerLoad
} from '../../actions/offer.actions';

import {ActivatedRoute} from '@angular/router';
import {areOffersLoading, getOffers} from "../../selectors/offer.selector";
import {getOwnUser} from "../../selectors/user.selector";
import {Customer} from "../../models/Customer";
import {Project} from "../../models/Project";

@Component({
  selector: 'app-productoffers',
  templateUrl: './productoffers.component.html',
  styleUrls: ['./productoffers.component.scss']
})
export class ProductOffersComponent implements OnInit {

  offers: Offer[] = [];
  dataSource = null;
  columnsToDisplay = ['name'];

  isLoading$;




  showCreateProject = false;

  @Input() customerId: string;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>) {
  }

  ngOnInit() {
    console.log(this.store.pipe(select(getOffers)));

    this.store.pipe(select(getOffers)).subscribe(offers => {
      this.offers = offers;
      this.dataSource = new MatTableDataSource<Offer>(this.offers);
      console.log(this.offers);
      console.log(this.dataSource);
    });

    //this.isLoading$ = this.store.pipe(select(areOffersLoading));



    this.store.dispatch(new OffersForCustomerLoad(this.customerId));
    console.log("LOAD STARTED");

    console.log(this.store);


  }


}
