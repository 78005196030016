import {Action} from '@ngrx/store';
import {Partner} from '../models/Partner';

export enum PartnerActionTypes {
  ALL_LOAD = '[Partner] All Loading...',
  ALL_LOADED = '[Partner] All Loaded.',
  SINGLE_LOAD = '[Partner] Single Loading...',
  SINGLE_LOADED = '[Partner] Single Loaded.',
  CREATE_SEND = '[Partner] creating....',
  CREATE_DONE = '[Partner] created.',
  UPDATE_SEND = '[Partner] updating...',
  UPDATE_DONE = '[Partner] updated.',
  UPDATE_SEND_FORM = '[Partner] form updating...'
}

export class PartnersLoad implements Action {
  readonly type = PartnerActionTypes.ALL_LOAD;
}

export class PartnersLoaded implements Action {
  readonly type = PartnerActionTypes.ALL_LOADED;

  constructor(public payload: Partner[]) {
  }
}

export class PartnerLoad implements Action {
  readonly type = PartnerActionTypes.SINGLE_LOAD;

  constructor(public payload: string) {
  }
}

export class PartnerLoaded implements Action {
  readonly type = PartnerActionTypes.SINGLE_LOADED;

  constructor(public payload: Partner) {
  }
}

export class PartnerCreate implements Action {
  readonly type = PartnerActionTypes.CREATE_SEND;

  constructor(public payload: Partner) {
  }
}

export class PartnerCreated implements Action {
  readonly type = PartnerActionTypes.CREATE_DONE;

  constructor(public payload: Partner) {
  }
}

export class PartnerUpdate implements Action {
  readonly type = PartnerActionTypes.UPDATE_SEND;

  constructor(public payload: Partner) {
  }
}

export class PartnerUpdateForm implements Action {
  readonly type = PartnerActionTypes.UPDATE_SEND_FORM;

  constructor(public payload: FormData) {
  }
}

export class PartnerUpdated implements Action {
  readonly type = PartnerActionTypes.UPDATE_DONE;

  constructor(public payload: Partner) {
  }
}

export type PartnerActions =
  PartnersLoad
  | PartnersLoaded
  | PartnerCreate
  | PartnerCreated
  | PartnerLoad
  | PartnerLoaded
  | PartnerUpdate
  | PartnerUpdateForm
  | PartnerUpdated;
