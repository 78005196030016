import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {UserActionTypes} from '../actions/user.actions';
import {UserService} from '../services/user.service';
import {UIService} from '../services/ui.service';
import {SnackbarNotification} from '../models/ui/snackbar-notification.model';
import {BaseEffects} from './base.effects';
import {UtilService} from '../services/util.service';

@Injectable()
export class UserEffects extends BaseEffects {
  @Effect()
  login$ = this.actions$
    .pipe(
      ofType(UserActionTypes.DO_LOGIN),
      mergeMap((action) => this.userService.login((action as any).payload.username, (action as any).payload.password)
        .pipe(
          map(response => ({
            type: UserActionTypes.LOGGED_IN,
            payload: {
              accessToken: response.access_token,
              refreshToken: response.refresh_token
            }
          })),
          catchError(() => {
            return of({
              type: UserActionTypes.DO_LOGIN_ERROR,
              payload: 'Fehler beim einloggen. Bitte überprüfen Sie Benutzername und Passwort.'
            });
          })
        )
      )
    );

  @Effect()
  loginError$ = this.actions$.pipe(
    ofType(UserActionTypes.DO_LOGIN_ERROR),
    mergeMap((action) => {
        this.uiService.dispatchNotification(SnackbarNotification.getErrorMessage((action as any).payload));
        return EMPTY;
      }
    )
  );

  @Effect()
  logout$ = this.actions$.pipe(
    ofType(UserActionTypes.LOGOUT),
    mergeMap((action) => {
        this.uiService.dispatchNotification(((action as any).payload));
        return EMPTY;
      }
    )
  );

  @Effect()
  loadOwnUser$ = this.actions$.pipe(
    ofType(UserActionTypes.OWN_LOAD),
    mergeMap(() => this.userService.own()
      .pipe(
        map(user => ({type: UserActionTypes.OWN_LOADED, payload: user})),
        catchError((error) => {
          this.handleServerError(error);
          return EMPTY;
        })
      )
    )
  );

  @Effect()
  loadUsers$ = this.actions$.pipe(
    ofType(UserActionTypes.ALL_LOAD),
    mergeMap(() => this.userService.all()
      .pipe(
        map(users => ({type: UserActionTypes.ALL_LOADED, payload: users})),
        catchError((error) => {
          this.handleServerError(error);
          return EMPTY;
        })
      )
    )
  );

  @Effect()
  createUser$ = this.actions$.pipe(
    ofType(UserActionTypes.CREATE_SEND),
    mergeMap((action) => this.userService.create((action as any).payload)
      .pipe(
        map(user => ({type: UserActionTypes.CREATE_DONE, payload: user})),
        catchError((error) => {
          this.handleServerError(error);
          return EMPTY;
        })
      )
    )
  );

  @Effect()
  updateUser$ = this.actions$.pipe(
    ofType(UserActionTypes.UPDATE_SEND),
    mergeMap((action) => this.userService.update((action as any).payload)
      .pipe(
        map(user => ({type: UserActionTypes.UPDATE_DONE, payload: user})),
        catchError((error) => {
          this.handleServerError(error);
          return EMPTY;
        })
      )
    )
  );

  constructor(
    actions$: Actions,
    uiService: UIService,
    utilService: UtilService,
    protected userService: UserService
  ) {
    super(actions$, uiService, utilService);
  }
}
