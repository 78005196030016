import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';

import {ActivatedRoute} from '@angular/router';
import {SetTitle} from '../../../../actions/ui.actions';
import * as fromRoot from '../../../../reducers';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


import {select, Store} from '@ngrx/store';
import {ProjectRoomType} from '../../../../models/project-room-type.model';
import {areProjectRoomtTypesLoading, getProjectRoomTypes} from '../../../../selectors/project-room-type.selector';
import {ProjectRoomTypeLoad, ProjectRoomTypesUpdate} from '../../../../actions/project-room-type.actions';


@Component({
  selector: 'app-project-room-type-detail',
  templateUrl: './project-room-type-detail.component.html',
  styleUrls: ['./project-room-type-detail.component.scss']
})
export class ProjectRoomTypeDetailComponent implements OnInit {

  id: string;
  projectRoomType: ProjectRoomType;
  isLoading: Boolean;
  editProjectRoomType = false;
  projectRoomTypeForm: FormGroup;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, private dialog: MatDialog, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm(null);

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.store.dispatch(new ProjectRoomTypeLoad(this.id));
    });

    this.store.pipe(select(getProjectRoomTypes)).subscribe(projectRoomTypes => {
      this.projectRoomType = projectRoomTypes.find(p => p.id === this.id);


      if (this.projectRoomType) {
        this.store.dispatch(new SetTitle('Projekt Raum Typ Übersicht > ' + this.projectRoomType.name));

        this.projectRoomTypeForm = this.buildForm(this.projectRoomType);
      }
    });
    this.store.pipe(select(areProjectRoomtTypesLoading)).subscribe(loading => this.isLoading = loading);
  }

  confirmDeleteProjectRoomType() {
    console.log('wirklich löschen?');
    //this.dialog.open(ConfirmDeleteCustomerComponent);
  }


  updateProjectRoomType() {
    //console.log(this.projectRoomType);

    const projectRoomType = new ProjectRoomType();
    projectRoomType.id = this.projectRoomType.id;
    projectRoomType.name = this.projectRoomTypeForm.get('name').value;
    projectRoomType.nbFloorSensors = this.projectRoomTypeForm.get('nbFloorSensors').value;
    projectRoomType.nbRoomSensors = this.projectRoomTypeForm.get('nbRoomSensors').value;
    projectRoomType.priority = this.projectRoomTypeForm.get('priority').value;

    this.store.dispatch(new ProjectRoomTypesUpdate(projectRoomType));
  }


  buildForm(projectRoomType: ProjectRoomType | null) {
    return this.formBuilder.group({
      name: [projectRoomType ? projectRoomType.name : '', Validators.required],
      nbFloorSensors: [projectRoomType ? projectRoomType.nbFloorSensors : 0, Validators.required],
      nbRoomSensors: [projectRoomType ? projectRoomType.nbRoomSensors : 0, Validators.required],
      priority: [projectRoomType ? projectRoomType.priority : 0, Validators.required],
    });
  }
}
