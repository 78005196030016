import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UserState} from '../reducers/user.reducer';


export const selectState = createFeatureSelector('user');

export const selectAccessToken = (state: UserState) => state.accessToken;
export const selectUsers = (state: UserState) => state.users;
export const selectOwnUser = (state: UserState) => state.ownUser;
export const selectUserLoading = (state: UserState) => state.isLoading;

export const getAccessToken = createSelector(selectState, selectAccessToken);
export const getUsers = createSelector(selectState, selectUsers);
export const getOwnUser = createSelector(selectState, selectOwnUser);
export const areUsersLoading = createSelector(selectState, selectUserLoading);
