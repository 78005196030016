export class BuildingType {
  id: string;
  name: string;
  work: number;
  additionWindowNoInsulation: number;
  additionUnheatedUnder: number;
  additionUnheatedAbove: number;
  additionOuterWall1: number;
  additionOuterWall2: number;
  additionOuterWall3: number;
  additionOuterWall4: number;
  factorWindowPerSqm: number;
  additionMoreThanFactorWindowPerSqm: number;
  adaptionWetRoom: number;
  adaptionLeisureRoom: number;
  adaptionBedRoom: number;
  adaptionHallway: number;
  adaptionSideRoom: number;
  additionWallMount: number;
  workPerInframeshSegment: number;
  discountPremium: number;
  additionIRPanels: number;
  altitudeThresholdForAdditions: number;
  altitudeAdditionFactor: number;
  transformationDeviation: number;
  heatinghours_basic: number;
  heatinghours_medium: number;
  heatinghours_premium: number;
  heatinghours_konvektor: number;
  heatinghours_irpanel: number;
  heatinghours_infrafloor_parkett: number;
  heatinghours_infrafloor_fliesen: number;
  heatinghours_infrafloor_estrich: number;
}
