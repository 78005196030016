import {Component, OnInit} from '@angular/core';
import {Partner} from '../../../models/Partner';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {MatDialog} from '@angular/material';
import {pipe} from 'rxjs';
import {getPartnerStateData} from '../../../selectors/partner.selector';
import {areUsersLoading, getOwnUser} from '../../../selectors/user.selector';
import {PartnersLoad} from '../../../actions/partner.actions';
import {SetTitle} from '../../../actions/ui.actions';
import {User} from '../../../models/user.model';
import {UserUpdate} from '../../../actions/user.actions';
import {SnackbarNotification} from '../../../models/ui/snackbar-notification.model';
import {UIService} from '../../../services/ui.service';

@Component({
  selector: 'app-my-user',
  templateUrl: './my-user.component.html',
  styleUrls: ['./my-user.component.scss']
})
export class MyUserComponent implements OnInit {

  partners: Partner[] = [];
  user: User;
  isLoading$;
  editUser = false;

  userForm: FormGroup;
  passwordForm: FormGroup;

  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog, private formBuilder: FormBuilder, private uiService: UIService) {
  }

  ngOnInit() {

    this.userForm = this.buildForm(null);

    this.isLoading$ = this.store.select(pipe(areUsersLoading));

    this.store.select(pipe(getOwnUser)).subscribe(user => {
      this.user = user;
      this.userForm = this.buildForm(user);
      this.passwordForm = this.formBuilder.group({
        password: ['', Validators.required],
        passwordRepeat: ['', Validators.required]
      }, {validator: this.checkPasswords});
    });

    this.store.select(pipe(getPartnerStateData)).subscribe(partners => {
      this.partners = partners;
    });

    this.store.dispatch(new PartnersLoad());

    this.store.dispatch(new SetTitle('Mein Benutzer'));


  }

  updatePassword() {
    this.user.password = this.passwordForm.get('password').value;
    this.store.dispatch(new UserUpdate(this.user));
    this.passwordForm = this.formBuilder.group({
      password: ['', Validators.required],
      passwordRepeat: ['', Validators.required]
    }, {validator: this.checkPasswords});
    this.uiService.dispatchNotification(SnackbarNotification.getInfoMessage('Passwort erfolgreich geändert'));
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.passwordRepeat.value;

    return pass === confirmPass ? null : {notSame: true};
  }

  buildForm(user: User | null) {
    return this.formBuilder.group({
      firstname: [user ? user.firstname : '', Validators.required],
      surname: [user ? user.surname : '', Validators.required],
      role: [user ? user.role : ''],
      partner: [user ? this.getPartnerName(user.partner) : ''],
      email: [user ? user.email : '', Validators.required],
      phone: [user ? user.phone : '']
    });
  }

  updateUser() {
    const u = this.user;

    u.firstname = this.userForm.get('firstname').value;
    u.surname = this.userForm.get('surname').value;
    u.email = this.userForm.get('email').value;
    u.phone = this.userForm.get('phone').value;

    this.store.dispatch(new UserUpdate(u));
  }

  getPartnerName(id: string) {
    const partner = this.partners.find(p => p.id === id);

    return partner ? partner.companyName : '';
  }
}
