import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import * as fromRoot from '../../../../reducers';
import {InframeshMountingStyle} from '../../../../models/inframesh-mounting-style.model';

import {getInframeshMountingStyles, areInframeshMountingStylesLoading} from '../../../../selectors/inframesh-mounting-style.selector';
import {SetTitle} from '../../../../actions/ui.actions';
import {
  InframeshMountingStyleCreate,
  InframeshMountingStyleLoad, InframeshMountingStyleUpdate
} from '../../../../actions/inframesh-mounting-style.actions';

@Component({
  selector: 'app-inframesh-mounting-style-detail',
  templateUrl: './inframesh-mounting-style-detail.component.html',
  styleUrls: ['./inframesh-mounting-style-detail.component.scss']
})
export class InframeshMountingStyleDetailComponent implements OnInit {

  id: string;
  inframeshMountingStyle: InframeshMountingStyle;
  isLoading: Boolean;
  editInframeshMountingStyle = false;
  inframeshMountingStyleForm: FormGroup;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, private dialog: MatDialog, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.inframeshMountingStyleForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.store.dispatch(new InframeshMountingStyleLoad(this.id));
    });

    this.store.pipe(select(getInframeshMountingStyles)).subscribe(inframeshMountingStyles => {
      this.inframeshMountingStyle = inframeshMountingStyles.find(c => c.id === this.id);

      if (this.inframeshMountingStyle) {
        this.store.dispatch(new SetTitle('Inframesh Montage Style Übersicht > ' + this.inframeshMountingStyle.name));

        this.inframeshMountingStyleForm.get('name').setValue(this.inframeshMountingStyle.name);
      }
    });

    this.store.pipe(select(areInframeshMountingStylesLoading)).subscribe(loading => this.isLoading = loading);


  }

  confirmDeleteInframeshMountingStyle() {
    //this.dialog.open(ConfirmDeleteCustomerComponent);
  }


  updateInframeshMountingStyle() {
    const inframeshMountingStyle = new InframeshMountingStyle();
    inframeshMountingStyle.id = this.inframeshMountingStyle.id;
    inframeshMountingStyle.name = this.inframeshMountingStyleForm.get('name').value;
    this.store.dispatch(new InframeshMountingStyleUpdate(inframeshMountingStyle));
  }
}
