import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectRoom} from '../models/project-room.model';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectRoomService extends BaseService<ProjectRoom> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_PROJECTROOM);
  }
}
