import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Product} from '../models/product.model';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService<Product> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_PRODUCT);
  }
}
