import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatChipInputEvent, MatDialogRef} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {getCustomerStateData} from '../../../../../selectors/customer.selector';

@Component({
  selector: 'email-offer',
  templateUrl: './email-offer.component.html',
  styleUrls: ['./email-offer.component.scss']
})
export class EmailOfferComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  recipients: string[] = [];
  emailForm: FormGroup;

  constructor(private store: Store<fromRoot.State>,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<EmailOfferComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit() {
    this.store.pipe(select(getCustomerStateData)).subscribe(customers => {
      if (customers) {
        this.data.recipients.forEach(r => this.recipients.push(customers.find(c => c.id === r).email));
      }
    });

    this.emailForm = this.formBuilder.group({
      sender: [this.data.sender, Validators.required]
    });
  }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.recipients.push(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  remove(recipient) {
    this.recipients.splice(this.recipients.indexOf(recipient), 1);
  }
}
