import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {UIService} from './services/ui.service';
import {SnackbarComponent} from './components/framework/snackbar/snackbar.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'simplyheat-admin-tool-web-frontend';

  constructor(
    private snackBar: MatSnackBar,
    private uiService: UIService
  ) {

    this.uiService.getSnackbarNotifications().subscribe(notification => {
      const snackBarRef = this.snackBar.openFromComponent(SnackbarComponent, {
        data: {
          notification: notification
        },
        duration: 2500
      });

      snackBarRef.afterDismissed().subscribe(() => {
        //console.log('dismissed');
      });
    });
  }
}
