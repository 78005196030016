export class Partner {
  id: string;
  partnerNumber: string;
  companyName: string;
  uid: string;
  registerNumber: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
  logo: FormData;
  bankName: string;
  bankIban: string;
  bankBic: string;
  offerIntro: string;
  offerTerms: string;
  website: string;
  globalBilling: string;
  prabatt: string;
}
