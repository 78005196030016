import {Component, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';

import * as fromRoot from '../../reducers';
import {PartnerCreate, PartnersLoad, PartnerUpdate, PartnerUpdateForm} from '../../actions/partner.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {User} from '../../models/user.model';
import {SetTitle} from '../../actions/ui.actions';
import {Partner} from '../../models/Partner';
import {getPartnerStateData, getPartnerStateIsLoading} from '../../selectors/partner.selector';
import {getOwnUser} from '../../selectors/user.selector';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  partners: Partner[] = [];
  dataSource = null;
  columnsToDisplay = ['companyName'];
  isLoading$;
  resultsLength = 0;
  showCreatePartner = false;
  partnerForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: User;

  selectedPartner: Partner;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;
    });

    this.store.pipe(select(getPartnerStateData)).subscribe(partners => {
      this.partners = partners;
      this.resultsLength = partners.length;
      this.dataSource = new MatTableDataSource<Partner>(this.partners);
      // TODO: https://blog.angular-university.io/angular-material-data-table/
      setTimeout(() => this.dataSource.paginator = this.paginator, 300);
    });

    this.isLoading$ = this.store.pipe(select(getPartnerStateIsLoading));
    this.store.dispatch(new PartnersLoad());

    this.partnerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      uid: [''],
      registerNumber: [''],
      street: [''],
      zipCode: [''],
      city: [''],
      country: ['Österreich'],
      email: [''],
      phone: [''],
      bankName: [''],
      bankIban: [''],
      bankBic: [''],
      globalBilling: ['']
    });

    this.store.dispatch(new SetTitle('Kundenübersicht'));
  }

  createPartner() {

    if (!this.selectedPartner) {
      const p: Partner = new Partner();
      p.companyName = this.partnerForm.get('companyName').value;
      p.street = this.partnerForm.get('street').value;
      p.zipCode = this.partnerForm.get('zipCode').value;
      p.city = this.partnerForm.get('city').value;
      p.country = this.partnerForm.get('country').value;
      p.phone = this.partnerForm.get('phone').value;
      p.email = this.partnerForm.get('email').value;
      p.bankName = this.partnerForm.get('bankName').value;
      p.bankIban = this.partnerForm.get('bankIban').value;
      p.bankBic = this.partnerForm.get('bankBic').value;
      p.uid = this.partnerForm.get('uid').value;
      p.registerNumber = this.partnerForm.get('registerNumber').value;
      p.globalBilling = this.partnerForm.get('globalBilling').value ? "1" : "0";


      this.store.dispatch(new PartnerCreate(p));
    } else {
      const formData = new FormData();

      formData.append('uid', this.partnerForm.get('uid').value);
      formData.append('registerNumber', this.partnerForm.get('registerNumber').value);
      formData.append('street', this.partnerForm.get('street').value);
      formData.append('zipCode', this.partnerForm.get('zipCode').value);
      formData.append('city', this.partnerForm.get('city').value);
      formData.append('country', this.partnerForm.get('country').value);
      formData.append('email', this.partnerForm.get('email').value);
      formData.append('phone', this.partnerForm.get('phone').value);
      formData.append('id', this.selectedPartner.id);
      formData.append('bankName', this.partnerForm.get('bankName').value);
      formData.append('bankIban', this.partnerForm.get('bankIban').value);
      formData.append('bankBic', this.partnerForm.get('bankBic').value);
      formData.append('companyName', this.partnerForm.get('companyName').value);
      formData.append('globalBilling', this.partnerForm.get('globalBilling').value ? "1" : "0");


      this.store.dispatch(new PartnerUpdateForm(formData));
    }

    this.closePopup();
  }

  closePopup() {
    this.selectedPartner = undefined;
    this.partnerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      uid: [''],
      registerNumber: [''],
      street: [''],
      zipCode: [''],
      city: [''],
      country: ['Österreich'],
      email: [''],
      phone: [''],
      bankName: [''],
      bankIban: [''],
      bankBic: [''],
      globalBilling: [''],
    });
    this.showCreatePartner = false;
  }

  updatePartner(partner: Partner) {
    this.selectedPartner = partner;

    this.partnerForm = this.formBuilder.group({
      companyName: [partner.companyName, Validators.required],
      uid: [partner.uid],
      registerNumber: [partner.registerNumber],
      street: [partner.street],
      zipCode: [partner.zipCode],
      city: [partner.city],
      country: [partner.country],
      email: [partner.email],
      phone: [partner.phone],
      bankName: [partner.bankName],
      bankIban: [partner.bankIban],
      bankBic: [partner.bankBic],
      globalBilling: [partner.globalBilling == "1"],
    });

    this.showCreatePartner = true;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
