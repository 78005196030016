import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {ProjectRoomTypeActionTypes} from '../actions/project-room-type.actions';
import {ProjectRoomTypeService} from '../services/project-room-type.service';
import {BuildingTypeActionTypes} from "../actions/building-type.actions";

@Injectable()
export class ProjectRoomTypeEffects {

  @Effect()
  loadProjectRoomTypes$ = this.actions$
    .pipe(
      ofType(ProjectRoomTypeActionTypes.ALL_LOAD),
      mergeMap(() => this.projectRoomTypeService.all()
        .pipe(
          map(projectRoomTypes => ({type: ProjectRoomTypeActionTypes.ALL_LOADED, payload: projectRoomTypes})),
          catchError(() => EMPTY)
        )
      )
    );
  @Effect()
  loadProjectRoomType$ = this.actions$
    .pipe(
      ofType(ProjectRoomTypeActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.projectRoomTypeService.get((action as any).payload)
        .pipe(
          map(projectRoomType => ({type: ProjectRoomTypeActionTypes.SINGLE_LOADED, payload: projectRoomType})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createProjectRoomType$ = this.actions$
    .pipe(
      ofType(ProjectRoomTypeActionTypes.CREATE_SEND),
      mergeMap((action) => this.projectRoomTypeService.create((action as any).payload)
        .pipe(
          map(projectRoomType => ({type: ProjectRoomTypeActionTypes.CREATE_DONE, payload: projectRoomType})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updateProjectRoomType$ = this.actions$
    .pipe(
      ofType(ProjectRoomTypeActionTypes.UPDATE_SEND),
      mergeMap((action) => this.projectRoomTypeService.update((action as any).payload)
        .pipe(
          map(projectRoomType => ({type: ProjectRoomTypeActionTypes.UPDATE_DONE, payload: projectRoomType})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private projectRoomTypeService: ProjectRoomTypeService
  ) {
  }
}
