import {Action} from '@ngrx/store';
import {ProjectRoomType} from '../models/project-room-type.model';

export enum ProjectRoomTypeActionTypes {
  ALL_LOAD = '[ProjectRoomTypes] All Loading...',
  ALL_LOADED = '[ProjectRoomTypes] All Loaded.',
  SINGLE_LOAD = '[ProjectRoomTypes] Single Loading...',
  SINGLE_LOADED = '[ProjectRoomTypes] Single Loaded.',
  CREATE_SEND = '[ProjectRoomTypes] creating...',
  CREATE_DONE = '[ProjectRoomTypes] created.',
  UPDATE_SEND = '[ProjectRoomTypes] updating...',
  UPDATE_DONE = '[ProjectRoomTypes] updated.'
}

export class ProjectRoomTypesLoad implements Action {
  readonly type = ProjectRoomTypeActionTypes.ALL_LOAD;
}

export class ProjectRoomTypesLoaded implements Action {
  readonly type = ProjectRoomTypeActionTypes.ALL_LOADED;

  constructor(public payload: ProjectRoomType[]) {
  }
}

export class ProjectRoomTypeLoad implements Action {
  readonly type = ProjectRoomTypeActionTypes.SINGLE_LOAD;
  constructor(public payload: string) {}
}

export class ProjectRoomTypeLoaded implements Action {
  readonly type = ProjectRoomTypeActionTypes.SINGLE_LOADED;
  constructor(public payload: ProjectRoomType) {}
}

export class ProjectRoomTypesCreate implements Action {
  readonly type = ProjectRoomTypeActionTypes.CREATE_SEND;

  constructor(public payload: ProjectRoomType) {
  }
}

export class ProjectRoomTypesCreated implements Action {
  readonly type = ProjectRoomTypeActionTypes.CREATE_DONE;

  constructor(public payload: ProjectRoomType) {
  }
}

export class ProjectRoomTypesUpdate implements Action {
  readonly type = ProjectRoomTypeActionTypes.UPDATE_SEND;

  constructor(public payload: ProjectRoomType) {
  }
}

export class ProjectRoomTypesUpdated implements Action {
  readonly type = ProjectRoomTypeActionTypes.UPDATE_DONE;

  constructor(public payload: ProjectRoomType) {
  }
}

export type ProjectRoomTypeActions =
  ProjectRoomTypesLoad | ProjectRoomTypesLoaded |
  ProjectRoomTypeLoad | ProjectRoomTypeLoaded |
  ProjectRoomTypesCreate | ProjectRoomTypesCreated |
  ProjectRoomTypesUpdate | ProjectRoomTypesUpdated;
