import {DownloadFile} from '../models/download.file.model';
import {DownloadActionTypes, DownloadFileActions} from '../actions/download.actions';

export interface DownloadFileState {
  isLoading: Boolean;
  files: DownloadFile[];
}

export const inititalState: DownloadFileState = {
  isLoading: false,
  files: []
};

export function reducer(
  state = inititalState,
  action: DownloadFileActions
): DownloadFileState {
  switch (action.type) {
    case DownloadActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DownloadActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        files: action.payload
      };
    }

    case DownloadActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DownloadActionTypes.SINGLE_LOADED: {
      let files = state.files;

      if (files.length > 0) {
        if (files.filter(c => c.id === action.payload.id).length > 0) {
          files.push(action.payload);
        }
      } else {
        files = [];
        files.push(action.payload);
      }

      return {
        ...state,
        isLoading: false,
        files: files
      };
    }

    case DownloadActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DownloadActionTypes.CREATE_DONE: {
      const files = Object.assign([], state.files);

      files.push(action.payload);

      return {
        ...state,
        isLoading: false,
        files: files
      };
    }

    case DownloadActionTypes.DELETE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case DownloadActionTypes.DELETE_DONE: {
      let files = Object.assign([], state.files);

      if (files.length > 0) {
        files.splice(files.indexOf(files.find(f => f.id === action.payload)), 1);
      } else {
        files = [];
      }
      
      return {
        ...state,
        isLoading: false,
        files: files
      };
    }

    default: {
      return state;
    }
  }
}
