import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {ProjectRoomService} from '../services/project-room.service';
import {ProjectRoomActionTypes} from '../actions/project-room.actions';

@Injectable()
export class ProjectRoomEffects {

  @Effect()
  loadProjectRooms$ = this.actions$
    .pipe(
      ofType(ProjectRoomActionTypes.ALL_LOAD),
      mergeMap(() => this.projectRoomService.all()
        .pipe(
          map(projectRooms => ({type: ProjectRoomActionTypes.ALL_LOADED, payload: projectRooms})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createProjectRoom$ = this.actions$
    .pipe(
      ofType(ProjectRoomActionTypes.CREATE_SEND),
      mergeMap((action) => this.projectRoomService.create((action as any).payload)
        .pipe(
          map(projectRoom => ({type: ProjectRoomActionTypes.CREATE_DONE, payload: projectRoom})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updateProjectRoom$ = this.actions$
    .pipe(
      ofType(ProjectRoomActionTypes.UPDATE_SEND),
      mergeMap((action) => this.projectRoomService.update((action as any).payload)
        .pipe(
          map(projectRoom => ({type: ProjectRoomActionTypes.UPDATE_DONE, payload: projectRoom})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  deleteProjectRoom$ = this.actions$
    .pipe(
      ofType(ProjectRoomActionTypes.DELETE_SEND),
      mergeMap((action) => this.projectRoomService.delete((action as any).payload)
        .pipe(
          map(roomId => ({type: ProjectRoomActionTypes.DELETE_DONE, payload: roomId})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private projectRoomService: ProjectRoomService
  ) {
  }
}
