import {UIActions, UIActionTypes} from '../actions/ui.actions';

export interface UIState {
  title: string;
}

export const inititalState: UIState = {
  title: 'SimplyHeat Admin Tool',
};

export function reducer(
  state = inititalState,
  action: UIActions
): UIState {
  switch (action.type) {
    case UIActionTypes.SET_TITLE: {
      return {
        ...state,
        title: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
