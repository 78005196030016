import {Action} from '@ngrx/store';
import {DownloadFile} from '../models/download.file.model';

export enum DownloadActionTypes {
  ALL_LOAD = '[DownloadFile] All Loading...',
  ALL_LOADED = '[DownloadFile] All Loaded.',
  SINGLE_LOAD = '[DownloadFile] Single Loading...',
  SINGLE_LOADED = '[DownloadFile] Single Loaded.',
  CREATE_SEND = '[DownloadFile] creating...',
  CREATE_FORM_SEND = '[DownloadFile] creating form...',
  CREATE_DONE = '[DownloadFile] created.',
  DELETE_SEND = '[DownloadFile] deleting...',
  DELETE_DONE = '[DownloadFile] deleted.'
}

export class DownloadFilesLoad implements Action {
  readonly type = DownloadActionTypes.ALL_LOAD;
}

export class DownloadFilesLoaded implements Action {
  readonly type = DownloadActionTypes.ALL_LOADED;

  constructor(public payload: DownloadFile[]) {
  }
}

export class DownloadFileLoad implements Action {
  readonly type = DownloadActionTypes.SINGLE_LOAD;

  constructor(public payload: string) {
  }
}

export class DownloadFileLoaded implements Action {
  readonly type = DownloadActionTypes.SINGLE_LOADED;

  constructor(public payload: DownloadFile) {
  }
}

export class DownloadFileCreate implements Action {
  readonly type = DownloadActionTypes.CREATE_SEND;

  constructor(public payload: DownloadFile) {
  }
}

export class DownloadFileCreateWithForm implements Action {
  readonly type = DownloadActionTypes.CREATE_FORM_SEND;

  constructor(public payload: FormData) {
  }
}

export class DownloadFileCreated implements Action {
  readonly type = DownloadActionTypes.CREATE_DONE;

  constructor(public payload: DownloadFile) {
  }
}

export class DownloadFileDelete implements Action {
  readonly type = DownloadActionTypes.DELETE_SEND;

  constructor(public payload: string) {
  }
}

export class DownloadFileDeleted implements Action {
  readonly type = DownloadActionTypes.DELETE_DONE;

  constructor(public payload: string) {
  }
}

export type DownloadFileActions =
  DownloadFilesLoad | DownloadFilesLoaded |
  DownloadFileLoad | DownloadFileLoaded |
  DownloadFileCreate | DownloadFileCreated | DownloadFileCreateWithForm |
  DownloadFileDelete | DownloadFileDeleted;
