import {createFeatureSelector, createSelector, select} from '@ngrx/store';
import {PartnerState} from '../reducers/partner.reducer';


export const selectPartnerState = createFeatureSelector('partner');

export const selectPartners = (state: PartnerState) => state.partners;
export const selectPartnerIsLoading = (state: PartnerState) => state.isLoading;

export const getPartnerStateData = createSelector(selectPartnerState, selectPartners);
export const getPartnerStateIsLoading = createSelector(selectPartnerState, selectPartnerIsLoading);
