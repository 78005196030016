import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatTableModule,
  MatToolbarModule,
  MatPaginatorIntl,
  MatDialogModule,
  MatSelectModule,
  MatOptionModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatSnackBarModule,
  MatExpansionModule,
  MatStepperModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatChipsModule
} from '@angular/material';

import {NgModule, ModuleWithProviders} from '@angular/core';
import {MatPaginatorIntlDE} from '../german-paginator-intl';

@NgModule({
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTableModule,
    MatListModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatChipsModule
  ],
  exports: [
    MatButtonModule,
    MatCheckboxModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTableModule,
    MatListModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatChipsModule
  ],
})
export class MaterialModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: MaterialModule,
      providers: [{provide: MatPaginatorIntl, useClass: MatPaginatorIntlDE}]
    };
  }
}
