import {createFeatureSelector, createSelector} from '@ngrx/store';
import {OfferState} from '../reducers/offer.reducer';


export const selectOfferState = createFeatureSelector('offer');

export const selectOffers = (state: OfferState) => state.offers;
export const selectOfferIsLoading = (state: OfferState) => state.isLoading;

export const getOffers = createSelector(selectOfferState, selectOffers);
export const getOffersAll = createSelector(selectOfferState, selectOffers);
export const areOffersLoading = createSelector(selectOfferState, selectOfferIsLoading);
