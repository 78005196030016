export class User {
  id: string;
  firstname: string;
  surname: string;
  email: string;
  phone: string;
  role: string;
  partner: string;
  password: string;
}
