import {Injectable, isDevMode} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {map} from 'rxjs/operators';
import {getAccessToken, getOwnUser} from '../selectors/user.selector';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<fromRoot.State>
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(getAccessToken),
      map(accessToken => {
        if (!accessToken) {
          this.router.navigate(['/login']);
          return false;
        }

        return true;
      }));
  }
}
