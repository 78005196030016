import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Partner} from '../models/Partner';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends BaseService<Partner> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_PARTNER);
  }

  updateForm(formData: FormData): Observable<Partner> {
    return this.http.post<Partner>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/' + formData.get('id') +
      Constants.API_URL_UPDATE
      , formData);
  }
}
