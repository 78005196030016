import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {select, Store} from '@ngrx/store';
import {ActivatedRoute} from '@angular/router';
import * as fromRoot from '../../../../reducers';
import {BuildingTypeUpdate, BuildingTypeLoad} from '../../../../actions/building-type.actions';
import {BuildingType} from '../../../../models/BuildingType';
import {getBuildingTypes, areBuildingTypesLoading} from '../../../../selectors/building-type.selector';
import {SetTitle} from '../../../../actions/ui.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


@Component({
  selector: 'app-building-type-detail',
  templateUrl: './building-type-detail.component.html',
  styleUrls: ['./building-type-detail.component.scss']
})
export class BuildingTypeDetailComponent implements OnInit {

  id: string;
  buildingType: BuildingType;
  isLoading: Boolean;
  editBuildingType = false;
  buildingTypeForm: FormGroup;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, private dialog: MatDialog, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm(null);

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.store.dispatch(new BuildingTypeLoad(this.id));
    });

    this.store.pipe(select(getBuildingTypes)).subscribe(buildingTypes => {
      this.buildingType = buildingTypes.find(c => c.id === this.id);

      if (this.buildingType) {
        this.store.dispatch(new SetTitle('Gebäudeart Übersicht > ' + this.buildingType.name));

        this.buildingTypeForm = this.buildForm(this.buildingType);

      }
    });
    this.store.pipe(select(areBuildingTypesLoading)).subscribe(loading => this.isLoading = loading);
  }

  confirmDeleteBuildingType() {
    console.log('wirklich löschen?');
    //this.dialog.open(ConfirmDeleteCustomerComponent);
  }


  updateBuildingType() {
    const buildingType = new BuildingType();

    buildingType.id = this.buildingType.id;
    buildingType.name = this.buildingTypeForm.get('name').value;
    buildingType.adaptionBedRoom = this.buildingTypeForm.get('adaptionBedRoom').value;
    buildingType.adaptionHallway = this.buildingTypeForm.get('adaptionHallway').value;
    buildingType.adaptionLeisureRoom = this.buildingTypeForm.get('adaptionLeisureRoom').value;
    buildingType.adaptionSideRoom = this.buildingTypeForm.get('adaptionSideRoom').value;
    buildingType.adaptionWetRoom = this.buildingTypeForm.get('adaptionWetRoom').value;
    buildingType.additionIRPanels = this.buildingTypeForm.get('additionIRPanels').value;
    buildingType.additionMoreThanFactorWindowPerSqm = this.buildingTypeForm.get('additionMoreThanFactorWindowPerSqm').value;
    buildingType.additionOuterWall1 = this.buildingTypeForm.get('additionOuterWall1').value;
    buildingType.additionOuterWall2 = this.buildingTypeForm.get('additionOuterWall2').value;
    buildingType.additionOuterWall3 = this.buildingTypeForm.get('additionOuterWall3').value;
    buildingType.additionOuterWall4 = this.buildingTypeForm.get('additionOuterWall4').value;
    buildingType.additionUnheatedAbove = this.buildingTypeForm.get('additionUnheatedAbove').value;
    buildingType.additionUnheatedUnder = this.buildingTypeForm.get('additionUnheatedUnder').value;
    buildingType.additionWallMount = this.buildingTypeForm.get('additionWallMount').value;
    buildingType.additionWindowNoInsulation = this.buildingTypeForm.get('additionWindowNoInsulation').value;
    buildingType.altitudeAdditionFactor = this.buildingTypeForm.get('altitudeAdditionFactor').value;
    buildingType.altitudeThresholdForAdditions = this.buildingTypeForm.get('altitudeThresholdForAdditions').value;
    buildingType.factorWindowPerSqm = this.buildingTypeForm.get('factorWindowPerSqm').value;
    buildingType.transformationDeviation = this.buildingTypeForm.get('transformationDeviation').value;
    buildingType.work = this.buildingTypeForm.get('work').value;
    buildingType.workPerInframeshSegment = this.buildingTypeForm.get('workPerInframeshSegment').value;

    buildingType.heatinghours_basic = this.buildingTypeForm.get('heatinghours_basic').value;
    buildingType.heatinghours_medium = this.buildingTypeForm.get('heatinghours_medium').value;
    buildingType.heatinghours_premium = this.buildingTypeForm.get('heatinghours_premium').value;
    buildingType.heatinghours_konvektor = this.buildingTypeForm.get('heatinghours_konvektor').value;
    buildingType.heatinghours_irpanel = this.buildingTypeForm.get('heatinghours_irpanel').value;
    buildingType.heatinghours_infrafloor_parkett = this.buildingTypeForm.get('heatinghours_infrafloor_parkett').value;
    buildingType.heatinghours_infrafloor_fliesen = this.buildingTypeForm.get('heatinghours_infrafloor_fliesen').value;
    buildingType.heatinghours_infrafloor_estrich = this.buildingTypeForm.get('heatinghours_infrafloor_estrich').value;

    this.store.dispatch(new BuildingTypeUpdate(buildingType));

  }

  buildForm(buildingType: BuildingType | null) {
    return this.formBuilder.group({
      name: [buildingType ? buildingType.name : '', Validators.required],
      adaptionBedRoom: [buildingType ? buildingType.adaptionBedRoom : 0],
      adaptionHallway: [buildingType ? buildingType.adaptionHallway : 0],
      adaptionLeisureRoom: [buildingType ? buildingType.adaptionLeisureRoom : 0],
      adaptionSideRoom: [buildingType ? buildingType.adaptionSideRoom : 0],
      adaptionWetRoom: [buildingType ? buildingType.adaptionWetRoom : 0],
      additionIRPanels: [buildingType ? buildingType.additionIRPanels : 0],
      additionMoreThanFactorWindowPerSqm: [buildingType ? buildingType.additionMoreThanFactorWindowPerSqm : 0],
      additionOuterWall1: [buildingType ? buildingType.additionOuterWall1 : 0],
      additionOuterWall2: [buildingType ? buildingType.additionOuterWall2 : 0],
      additionOuterWall3: [buildingType ? buildingType.additionOuterWall3 : 0],
      additionOuterWall4: [buildingType ? buildingType.additionOuterWall4 : 0],
      additionUnheatedAbove: [buildingType ? buildingType.additionUnheatedAbove : 0],
      additionUnheatedUnder: [buildingType ? buildingType.additionUnheatedUnder : 0],
      additionWallMount: [buildingType ? buildingType.additionWallMount : 0],
      additionWindowNoInsulation: [buildingType ? buildingType.additionWindowNoInsulation : 0],
      altitudeAdditionFactor: [buildingType ? buildingType.altitudeAdditionFactor : 0],
      altitudeThresholdForAdditions: [buildingType ? buildingType.altitudeThresholdForAdditions : 0],
      factorWindowPerSqm: [buildingType ? buildingType.factorWindowPerSqm : 0],
      transformationDeviation: [buildingType ? buildingType.transformationDeviation : 0],
      work: [buildingType ? buildingType.work : 0],
      workPerInframeshSegment: [buildingType ? buildingType.workPerInframeshSegment : 0],

      heatinghours_basic: [buildingType ? buildingType.heatinghours_basic : 7],
      heatinghours_medium: [buildingType ? buildingType.heatinghours_medium : 7],
      heatinghours_premium: [buildingType ? buildingType.heatinghours_premium : 7],
      heatinghours_konvektor: [buildingType ? buildingType.heatinghours_konvektor : 7],
      heatinghours_irpanel: [buildingType ? buildingType.heatinghours_irpanel : 7],
      heatinghours_infrafloor_parkett: [buildingType ? buildingType.heatinghours_infrafloor_parkett : 7],
      heatinghours_infrafloor_fliesen: [buildingType ? buildingType.heatinghours_infrafloor_fliesen : 7],
      heatinghours_infrafloor_estrich: [buildingType ? buildingType.heatinghours_infrafloor_estrich : 7]
    });
  }
}
