import {Injectable} from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import {EMPTY, Observable, throwError} from 'rxjs';
import {map, catchError, first, flatMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import * as fromRoot from './reducers';
import {getAccessToken} from './selectors/user.selector';
import {UIService} from './services/ui.service';
import {SnackbarNotification} from './models/ui/snackbar-notification.model';
import {Logout} from './actions/user.actions';
import {Router} from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<fromRoot.State>,
    private uiService: UIService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(getAccessToken).pipe(
      first(),
      flatMap(accessToken => {
        const authReq = accessToken !== undefined ? request.clone({
          setHeaders: {Authorization: 'Bearer ' + accessToken},
        }) : request;

        return next.handle(authReq).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401 && !authReq.url.endsWith('token')) {
              this.store.dispatch(
                new Logout(SnackbarNotification.getErrorMessage('Ihre Session wurde beendet. Bitte loggen Sie sich erneut ein.'))
              );
              this.router.navigate(['/login']);
              return EMPTY;
            } else {
              return throwError(error);
            }
          })
        );
      }),
    );
  }
}
