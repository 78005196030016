import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './modules/material.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {LoginComponent} from './components/login/login.component'; // Angular CLI environemnt
import {NgxMaskModule} from 'ngx-mask';
import {AppContainerComponent} from './components/layouts/app-container/app-container.component';
import {LoginContainerComponent} from './components/layouts/login-container/login-container.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AuthGuard} from './guards/auth.guard';
import {CustomersComponent} from './components/customers/customers.component';
import {reducer as partnerReducer} from './reducers/partner.reducer';
import {reducer as customerReducer} from './reducers/customer.reducer';
import {reducer as buildingTypeReducer} from './reducers/building-type.reducer';
import {reducer as projectReducer} from './reducers/project.reducer';
import {reducer as UIReducer} from './reducers/ui.reducer';
import {reducer as projectRoomTypeReducer} from './reducers/project-room-type.reducer';
import {reducer as inframeshMountingStyleReducer} from './reducers/inframesh-mounting-style.reducer';
import {reducer as projectRoomReducer} from './reducers/project-room.reducer';
import {reducer as productReducer} from './reducers/product.reducer';
import {reducer as userReducer} from './reducers/user.reducer';
import {reducer as offerReducer} from './reducers/offer.reducer';
import {reducer as downloadReducer} from './reducers/downloads.reducer';

import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';

import {PartnerComponent} from './components/partner/partner.component';
import {CustomerEffects} from './effects/customer.effects';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomerService} from './services/customer.service';
import {CustomerDetailComponent} from './components/customers/customer-detail/customer-detail.component';
import {SettingsComponent} from './components/settings/settings.component';
import {BuildingTypeComponent} from './components/settings/building-type/building-type.component';
import {BuildingTypeService} from './services/building-type.service';
import {BuildingTypeEffects} from './effects/building-type.effects';
import {ProjectService} from './services/project.service';
import {ProjectEffects} from './effects/project.effects';
import {CustomerDetailProjectsComponent} from './components/customers/customer-detail/customer-detail-projects/customer-detail-projects.component';
import {CustomerDetailProductsComponent} from './components/customers/customer-detail/customer-detail-products/customer-detail-products.component';
import {UIService} from './services/ui.service';
import {UIEffects} from './effects/ui.effects';
import {ProjectDetailComponent} from './components/customers/customer-detail/project-detail/project-detail.component';
import {ProjectRoomTypeComponent} from './components/settings/project-room-type/project-room-type.component';
import {ProjectRoomTypeEffects} from './effects/project-room-type.effects';
import {ProjectRoomTypeService} from './services/project-room-type.service';
import {InframeshMountingStyleComponent} from './components/settings/inframesh-mounting-style/inframesh-mounting-style.component';
import {InframeshMountingStyleService} from './services/inframesh-mounting-style.service';
import {InframeshMountingStyleEffects} from './effects/inframesh-mounting-style.effects';
import {ProjectRoomService} from './services/project-room.service';
import {ProjectRoomEffects} from './effects/project-room.effects';
import {BuildingTypeDetailComponent} from './components/settings/building-type/building-type-detail/building-type-detail.component';
import {PartnerService} from './services/partner.service';
import {PartnerEffects} from './effects/partner.effects';
import {InframeshMountingStyleDetailComponent} from './components/settings/inframesh-mounting-style/inframesh-mounting-style-detail/inframesh-mounting-style-detail.component';
import {ProjectRoomTypeDetailComponent} from './components/settings/project-room-type/project-room-type-detail/project-room-type-detail.component';
import {ProductsComponent} from './components/settings/products/products.component';
import {ProductEffects} from './effects/product.effects';
import {ProductService} from './services/product.service';
import {ProductDetailComponent} from './components/settings/products/product-detail/product-detail.component';
import {UserService} from './services/user.service';
import {UserEffects} from './effects/user.effects';
import {AppInterceptor} from './app.interceptor';
import {SnackbarComponent} from './components/framework/snackbar/snackbar.component';
import {UtilService} from './services/util.service';
import {routerReducer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {RouterModule} from '@angular/router';
import {metaReducers} from './reducers';
import {LoaderComponent} from './components/framework/loader/loader.component';
import {EntityTableComponent} from './components/framework/entity-table/entity-table.component';
import {OfferOverlayComponent} from './components/customers/customer-detail/project-detail/offer-overlay/offer-overlay.component';
import {UnassignedCustomersComponent} from './components/customers/unassigned-customers/unassigned-customers.component';
import {MyPartnerComponent} from './components/partner/my-partner/my-partner.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {ShopArticlesComponent} from './components/customers/customer-detail/project-detail/shop-articles/shop-articles.component';
import {UsersComponent} from './components/users/users.component';
import {MyUserComponent} from './components/users/my-user/my-user.component';
import {ProjectOffersComponent} from './components/customers/customer-detail/project-detail/offers/project-offers.component';
import {OfferEffects} from './effects/offer.effects';
import {OfferService} from './services/offer.service';
import {OffersComponent} from './components/offers/offers.component';
import {ProductOffersComponent} from './components/productoffers/productoffers.component';
import {OfferConfirmComponent} from './components/customers/customer-detail/project-detail/offer-confirm/offer-confirm.component';
import {EmailOfferComponent} from './components/customers/customer-detail/project-detail/email-offer/email-offer.component';
import {DownloadsComponent} from './components/downloads/downloads.component';
import {DownloadsEffects} from './effects/downloads.effects';
import {DownloadsService} from './services/downloads.service';
import {DeleteConfirmComponent} from './components/downloads/delete-confirm/delete-confirm.component';
import {ProjectDownloadsComponent} from './components/customers/customer-detail/project-detail/project-downloads/downloads.component';
import {ProjectRoomDeleteConfirmComponent} from './components/customers/customer-detail/project-detail/project-room-delete-confirm/project-room-delete-confirm.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppContainerComponent,
    LoginContainerComponent,
    DashboardComponent,
    CustomersComponent,
    PartnerComponent,
    CustomerDetailComponent,
    SettingsComponent,
    BuildingTypeComponent,
    CustomerDetailProjectsComponent,
    CustomerDetailProductsComponent,
    ProjectDetailComponent,
    ProjectRoomTypeComponent,
    InframeshMountingStyleComponent,
    BuildingTypeDetailComponent,
    InframeshMountingStyleDetailComponent,
    ProjectRoomTypeDetailComponent,
    ProductsComponent,
    ProductDetailComponent,
    SnackbarComponent,
    LoaderComponent,
    EntityTableComponent,
    OfferOverlayComponent,
    UnassignedCustomersComponent,
    UnassignedCustomersComponent,
    MyPartnerComponent,
    ShopArticlesComponent,
    UsersComponent,
    MyUserComponent,
    ProjectOffersComponent,
    OffersComponent,
    OfferConfirmComponent,
    EmailOfferComponent,
    DownloadsComponent,
    DeleteConfirmComponent,
    ProjectDownloadsComponent,
    ProjectRoomDeleteConfirmComponent,
      ProductOffersComponent
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule.forRoot(),
    AppRoutingModule,
    StoreDevtoolsModule.instrument({
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    RouterModule.forRoot([
      // routes
    ]),
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    StoreModule.forRoot({
      partner: partnerReducer,
      customer: customerReducer,
      buildingType: buildingTypeReducer,
      project: projectReducer,
      ui: UIReducer,
      projectRoomType: projectRoomTypeReducer,
      inframeshMountingStyle: inframeshMountingStyleReducer,
      projectRoom: projectRoomReducer,
      product: productReducer,
      user: userReducer,
      offer: offerReducer,
      downloads: downloadReducer,
      router: routerReducer
    }, {metaReducers}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([
      CustomerEffects,
      BuildingTypeEffects,
      ProjectEffects,
      UIEffects,
      ProjectRoomTypeEffects,
      InframeshMountingStyleEffects,
      ProjectRoomEffects,
      PartnerEffects,
      ProductEffects,
      UserEffects,
      OfferEffects,
      DownloadsEffects
    ]),
    NgxMaskModule
  ],
  providers: [
    AuthGuard,
    CustomerService,
    BuildingTypeService,
    ProjectService,
    UIService,
    ProjectRoomTypeService,
    InframeshMountingStyleService,
    ProjectRoomService,
    PartnerService,
    ProductService,
    UserService,
    OfferService,
    UtilService,
    DownloadsService,
    {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: []}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SnackbarComponent,
    OfferOverlayComponent,
    OfferConfirmComponent,
    EmailOfferComponent,
    DeleteConfirmComponent,
    ProjectRoomDeleteConfirmComponent
  ]
})
export class AppModule {
}
