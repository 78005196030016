import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Login, UserLoad, UserOwnLoad} from '../../actions/user.actions';
import {getAccessToken, getOwnUser} from '../../selectors/user.selector';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public hidePassword = true;
  public loginState = LoginState.LOGIN;
  public LoginStates = LoginState;

  loginForm: FormGroup;

  constructor(
    private router: Router,
    private store: Store<any>,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.store.pipe(select(getAccessToken)).subscribe(accessToken => {
      if (accessToken) {
        this.store.pipe(select(getOwnUser)).subscribe(user => {
          if (user) {
            this.router.navigate(['/customers']);
          }
        });

        this.store.dispatch(new UserOwnLoad());
      }
    });
  }

  login() {
    this.store.dispatch(
      new Login({
          username: this.loginForm.get('username').value,
          password: this.loginForm.get('password').value
        }
      )
    );
  }
}

export enum LoginState {
  LOGIN,
  REGISTER,
  FORGOT_PASSWORD
}
