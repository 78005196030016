import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Project} from '../models/Project';
import {Constants} from '../app.constants';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ProjectProduct} from '../models/project-product.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BaseService<Project> {
  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_PROJECT);
  }

  products(project: Project): Observable<ProjectProduct[]> {
    return this.http.get<ProjectProduct[]>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/' + project.id +
      '/products'
    );
  }

  createProduct(product: ProjectProduct): Observable<ProjectProduct> {
    return this.http.post<ProjectProduct>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/' + product.project +
      '/products/create'
      , product);
  }

  deleteProduct(product: ProjectProduct): Observable<string> {
    return this.http.post<string>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/' + product.project +
      '/products/delete'
      , product);
  }
}
