import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ProjectRoomState} from '../reducers/project-room.reducer';


export const selectState = createFeatureSelector('projectRoom');

export const selectProjectRooms = (state: ProjectRoomState) => state.projectRooms;
export const selectProjectRoomsLoading = (state: ProjectRoomState) => state.isLoading;

export const getProjectRooms = createSelector(selectState, selectProjectRooms);
export const areProjectRoomtsLoading = createSelector(selectState, selectProjectRoomsLoading);
