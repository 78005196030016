import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';
import {DownloadFile} from '../models/download.file.model';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DownloadsService extends BaseService<DownloadFile> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_FILE_DOWLOADS);
  }

  createForm(formData: FormData): Observable<DownloadFile> {
    return this.http.post<DownloadFile>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      Constants.API_URL_CREATE
      , formData);
  }
}
