import {createFeatureSelector, createSelector} from '@ngrx/store';
import {DownloadFileState} from '../reducers/downloads.reducer';


export const selectState = createFeatureSelector('downloads');

export const selectFiles = (state: DownloadFileState) => state.files;
export const selectFileStateIsLoading = (state: DownloadFileState) => state.isLoading;

export const getDownloadFiles = createSelector(selectState, selectFiles);
export const areDownloadFilesLoading = createSelector(selectState, selectFileStateIsLoading);
