import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {routerReducer} from '@ngrx/router-store';
import {UserActionTypes} from '../actions/user.actions';

export interface State {
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [clear] : [clear];

export function clear(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {

    switch (action.type) {
      case UserActionTypes.LOGOUT:
        state = undefined;
    }

    return reducer(state, action);
  };
}
