import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UIService} from '../services/ui.service';

@Injectable()
export class UIEffects {
  constructor(
    private actions$: Actions,
    private uiService: UIService
  ) {
  }
}
