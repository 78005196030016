import {Product} from '../models/product.model';
import {ProductActions, ProductActionTypes} from '../actions/product.actions';
import {BuildingTypeActionTypes} from '../actions/building-type.actions';

export interface ProductState {
  isLoading: Boolean;
  products: Product[];
}

export const inititalState: ProductState = {
  isLoading: false,
  products: []
};

export function reducer(
  state = inititalState,
  action: ProductActions
): ProductState {
  switch (action.type) {
    case ProductActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProductActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        products: action.payload
      };
    }

    case ProductActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProductActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProductActionTypes.SINGLE_LOADED: {
      let products = Object.assign([], state.products);

      if (products.length > 0) {
        const product = products.find(p => p.id === action.payload.id);

        if (product) {
          products.splice(products.indexOf(product), 1);
        }
      } else {
        products = [];
      }

      products.push(action.payload);

      return {
        ...state,
        isLoading: false,
        products: products
      };
    }

    case ProductActionTypes.CREATE_DONE: {
      const products = Object.assign([], state.products);

      products.push(action.payload);

      return {
        ...state,
        isLoading: false,
        products: products
      };
    }

    case ProductActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProductActionTypes.UPDATE_DONE: {
      let products = Object.assign([], state.products);

      if (products.length > 0) {
        const updatedProducts = products.filter(p => p.id === action.payload.id);

        if (updatedProducts) {
          updatedProducts.forEach(p => products.splice(products.indexOf(p), 1));
        }
      } else {
        products = [];
      }

      products.push(action.payload);

      return {
        ...state,
        isLoading: false,
        products: products
      };
    }

    default: {
      return state;
    }
  }
}
