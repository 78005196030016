import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {DownloadActionTypes} from '../actions/download.actions';
import {DownloadsService} from '../services/downloads.service';

@Injectable()
export class DownloadsEffects {
  @Effect()
  loadFiles$ = this.actions$
    .pipe(
      ofType(DownloadActionTypes.ALL_LOAD),
      mergeMap(() => this.downloadFileService.all()
        .pipe(
          map(files => ({type: DownloadActionTypes.ALL_LOADED, payload: files})),
          catchError(() => EMPTY)
        )
      )
    );
  @Effect()
  loadFile$ = this.actions$
    .pipe(
      ofType(DownloadActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.downloadFileService.get((action as any).payload)
        .pipe(
          map(file => ({type: DownloadActionTypes.SINGLE_LOADED, payload: file})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createFile$ = this.actions$
    .pipe(
      ofType(DownloadActionTypes.CREATE_FORM_SEND),
      mergeMap((action) => this.downloadFileService.createForm((action as any).payload)
        .pipe(
          map(file => ({type: DownloadActionTypes.CREATE_DONE, payload: file})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  deleteFile$ = this.actions$
    .pipe(
      ofType(DownloadActionTypes.DELETE_SEND),
      mergeMap((action) => this.downloadFileService.delete((action as any).payload)
        .pipe(
          map(fileId => ({type: DownloadActionTypes.DELETE_DONE, payload: fileId})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private downloadFileService: DownloadsService
  ) {
  }
}
