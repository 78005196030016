import {Action} from '@ngrx/store';
import {Project} from '../models/Project';
import {ProjectProduct} from '../models/project-product.model';

export enum ProjectActionTypes {
  ALL_LOAD = '[Project] All Loading...',
  ALL_LOADED = '[Project] All Loaded.',
  SINGLE_LOAD = '[Project] Single Loading...',
  SINGLE_LOADED = '[Project] Single Loaded.',
  CREATE_SEND = '[Project] creating...',
  CREATE_DONE = '[Project] created.',
  UPDATE_SEND = '[Project] updating...',
  UPDATE_DONE = '[Project] updated.',
  PROJECT_PRODUCTS_LOAD = '[Project] products loading...',
  PROJECT_PRODUCTS_LOADED = '[Project] products loaded',
  PROJECT_PRODUCT_CREATE = '[Project] product creating...',
  PROJECT_PRODUCT_CREATED = '[Project] product created.',
  PROJECT_PRODUCT_DELETE = '[Project] product deleting...',
  PROJECT_PRODUCT_DELETED = '[Project] product deleted.'
}

export class ProjectsLoad implements Action {
  readonly type = ProjectActionTypes.ALL_LOAD;
}

export class ProjectsLoaded implements Action {
  readonly type = ProjectActionTypes.ALL_LOADED;

  constructor(public payload: Project[]) {
  }
}

export class ProjectCreate implements Action {
  readonly type = ProjectActionTypes.CREATE_SEND;

  constructor(public payload: Project) {
  }
}

export class ProjectCreated implements Action {
  readonly type = ProjectActionTypes.CREATE_DONE;

  constructor(public payload: Project) {
  }
}

export class ProjectLoad implements Action {
  readonly type = ProjectActionTypes.SINGLE_LOAD;

  constructor(public payload: string) {
  }
}

export class ProjectLoaded implements Action {
  readonly type = ProjectActionTypes.SINGLE_LOADED;

  constructor(public payload: Project) {
  }
}

export class ProjectUpdate implements Action {
  readonly type = ProjectActionTypes.UPDATE_SEND;

  constructor(public payload: Project) {
  }
}

export class ProjectUpdated implements Action {
  readonly type = ProjectActionTypes.UPDATE_DONE;

  constructor(public payload: Project) {
  }
}

export class ProjectProductsLoad implements Action {
  readonly type = ProjectActionTypes.PROJECT_PRODUCTS_LOAD;

  constructor(public payload: Project) {
  }
}

export class ProjectProductsLoaded implements Action {
  readonly type = ProjectActionTypes.PROJECT_PRODUCTS_LOADED;

  constructor(public payload: ProjectProduct[]) {
  }
}

export class ProjectProductCreate implements Action {
  readonly type = ProjectActionTypes.PROJECT_PRODUCT_CREATE;

  constructor(public payload: ProjectProduct) {
  }
}

export class ProjectProductCreated implements Action {
  readonly type = ProjectActionTypes.PROJECT_PRODUCT_CREATED;

  constructor(public payload: ProjectProduct) {
  }
}

export class ProjectProductDelete implements Action {
  readonly type = ProjectActionTypes.PROJECT_PRODUCT_DELETE;

  constructor(public payload: ProjectProduct) {
  }
}

export class ProjectProductDeleted implements Action {
  readonly type = ProjectActionTypes.PROJECT_PRODUCT_DELETED;

  constructor(public payload: string) {
  }
}

export type ProjectActions =
  ProjectLoad
  | ProjectLoaded
  | ProjectsLoad
  | ProjectsLoaded
  | ProjectCreate
  | ProjectCreated
  | ProjectUpdate
  | ProjectUpdated
  | ProjectProductsLoad
  | ProjectProductsLoaded
  | ProjectProductCreate
  | ProjectProductCreated
  | ProjectProductDelete
  | ProjectProductDeleted
  ;
