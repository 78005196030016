import {Action} from '@ngrx/store';

export enum UIActionTypes {
  SET_TITLE = '[UI] setting title...'
}

export class SetTitle implements Action {
  readonly type = UIActionTypes.SET_TITLE;

  constructor(public payload: string) {
  }
}

export type UIActions = SetTitle;
