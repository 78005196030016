import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, of, pipe} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {Project} from '../../../../../models/Project';
import {DownloadFile} from '../../../../../models/download.file.model';
import {User} from '../../../../../models/user.model';
import {getOwnUser} from '../../../../../selectors/user.selector';
import {areDownloadFilesLoading, getDownloadFiles} from '../../../../../selectors/download-file.selector';
import {DownloadFileCreateWithForm, DownloadFileDelete, DownloadFilesLoad} from '../../../../../actions/download.actions';
import {DeleteConfirmComponent} from '../../../../downloads/delete-confirm/delete-confirm.component';
import {Offer} from '../../../../../models/offer';

@Component({
  selector: 'project-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class ProjectDownloadsComponent implements OnInit {

  columnsToDisplay = ['name'];
  showCreateFile = false;
  dataSource = null;
  fileToUpload: File = null;
  uploadControl = new FormControl();
  fileForm: FormGroup;
  filteredCategories: Observable<string[]>;
  isLoading$;
  categories: string[] = [];
  files: DownloadFile[];

  @Input() project: Project;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.isLoading$ = this.store.select(pipe(areDownloadFilesLoading));

    this.store.select(pipe(getDownloadFiles)).subscribe(files => {
      this.files = files.filter(f => f.project === this.project.id);
      this.dataSource = new MatTableDataSource<DownloadFile>(this.files);
    });

    this.fileForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.store.dispatch(new DownloadFilesLoad());
  }

  resetForm() {
    this.fileForm = this.formBuilder.group({
      name: ['', Validators.required]
    });

    this.fileToUpload = null;
    this.uploadControl.setValue('');
    this.showCreateFile = false;
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  delete(f) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      data: {
        name: f.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DownloadFileDelete(f.id));
      }
    });
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    formData.append('name', this.fileForm.get('name').value);
    formData.append('category', '');
    formData.append('project', this.project.id);

    this.store.dispatch(new DownloadFileCreateWithForm(formData));
    this.resetForm();
  }
}
