import {CustomerActionTypes, CustomerActions} from '../actions/customer.actions';
import {Customer} from '../models/Customer';
import {BuildingTypeActionTypes} from '../actions/building-type.actions';

export interface CustomerState {
  isLoading: Boolean;
  selectedCustomer: Customer;
  customers: Customer[];
}

export const inititalState: CustomerState = {
  isLoading: false,
  selectedCustomer: undefined,
  customers: []
};

export function reducer(
  state = inititalState,
  action: CustomerActions
): CustomerState {
  switch (action.type) {
    case CustomerActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CustomerActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        customers: action.payload
      };
    }

    case CustomerActionTypes.ALL_UNASSIGNED_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CustomerActionTypes.ALL_UNASSIGNED_LOADED: {
      const customers = Object.assign([], state.customers);
      const updatedCustomers = [];

      action.payload.forEach(uC => updatedCustomers.concat(customers.filter(c => c.id === uC.id)));
      updatedCustomers.forEach(c => customers.splice(customers.indexOf(c), 1));

      customers.concat(action.payload);

      return {
        ...state,
        isLoading: false,
        customers: customers
      };
    }

    case CustomerActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CustomerActionTypes.SINGLE_LOADED: {
      let customers = state.customers;

      if (customers.length > 0) {
        if (customers.filter(c => c.id === action.payload.id).length > 0) {
          customers.push(action.payload);
        }
      } else {
        customers = [];
        customers.push(action.payload);
      }

      return {
        ...state,
        isLoading: false,
        customers: customers
      };
    }

    case CustomerActionTypes.PARTNER_ASSIGN: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CustomerActionTypes.PARTNER_ASSIGNED: {
      let customers = Object.assign([], state.customers);

      if (customers.length > 0) {
        const updatedCustomers = customers.filter(c => c.id === action.payload.id);

        if (updatedCustomers) {
          updatedCustomers.forEach(c => customers.splice(customers.indexOf(c), 1));
        }
      } else {
        customers = [];
      }

      customers.push(action.payload);

      return {
        ...state,
        isLoading: false,
        customers: customers
      };
    }

    case CustomerActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CustomerActionTypes.CREATE_DONE: {
      const customers = Object.assign([], state.customers); // TODO: reassign new array ALWAYS

      customers.push(action.payload);

      return {
        ...state,
        isLoading: false,
        customers: customers
      };
    }

    case CustomerActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CustomerActionTypes.UPDATE_DONE: {
      let customers = Object.assign([], state.customers);

      if (customers.length > 0) {
        const updatedCustomers = customers.filter(c => c.id === action.payload.id);

        if (updatedCustomers) {
          updatedCustomers.forEach(c => customers.splice(customers.indexOf(c), 1));
        }
      } else {
        customers = [];
      }

      customers.push(action.payload);

      return {
        ...state,
        isLoading: false,
        customers: customers
      };
    }

    default: {
      return state;
    }
  }
}
