import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {User} from '../models/user.model';
import {environment} from '../../environments/environment';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';
import {BuildingType} from '../models/BuildingType';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_USERS);
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(
      environment.baseUrl +
      Constants.API_OAUTH +
      '/token'
      , {
        username: username,
        password: password,
        grant_type: environment.grantType,
        client_id: environment.clientId,
        client_secret: environment.clientSecret
      });
  }

  own(): Observable<User> {
    return this.http.get<User>(
      environment.baseUrl +
      Constants.API_URL +
      Constants.API_URL_USERS +
      '/own'
    );
  }
}
