export class SnackbarNotification {
  status: SnackbarStatus;
  message: string;
  duration: number;

  constructor(message = '', status = SnackbarStatus.INFO, duration = 0) {
    this.message = message;
    this.status = status;
    this.duration = duration;
  }

  public static getInfoMessage(message: string): SnackbarNotification {
    return new SnackbarNotification(message, SnackbarStatus.INFO);
  }

  public static getErrorMessage(message: string): SnackbarNotification {
    return new SnackbarNotification(message, SnackbarStatus.ERROR);
  }
}

export enum SnackbarStatus {
  INFO,
  WARN,
  ERROR
}

