import {Component, OnDestroy, OnInit} from '@angular/core';
import {BuildingType} from '../../../models/BuildingType';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {MatTableDataSource} from '@angular/material';
import {areBuildingTypesLoading, getBuildingTypes} from '../../../selectors/building-type.selector';
import {BuildingTypesLoad, BuildingTypeCreate} from '../../../actions/building-type.actions';

import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackbarNotification} from '../../../models/ui/snackbar-notification.model';
import {UIService} from '../../../services/ui.service';

@Component({
  selector: 'building-type',
  templateUrl: './building-type.component.html',
  styleUrls: ['./building-type.component.scss']
})
export class BuildingTypeComponent implements OnInit {

  buildingTypes: BuildingType[] = [];
  dataSource = null;
  columnsToDisplay = ['name'];
  buildingTypeForm: FormGroup;
  showCreateBuildingType = false;

  isLoading$;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder, private uiService: UIService) {
  }

  ngOnInit() {
    this.store.pipe(select(getBuildingTypes)).subscribe(buildingTypes => {
      this.buildingTypes = buildingTypes;
      this.dataSource = new MatTableDataSource<BuildingType>(buildingTypes);
    });

    this.isLoading$ = this.store.pipe(select(areBuildingTypesLoading));
    this.store.dispatch(new BuildingTypesLoad());

    this.buildingTypeForm = this.formBuilder.group({
      name: ['', Validators.required],
      adaptionBedRoom: [0],
      adaptionHallway: 0,
      adaptionLeisureRoom: 0,
      adaptionSideRoom: 0,
      adaptionWetRoom: 0,
      additionIRPanels: 0,
      additionMoreThanFactorWindowPerSqm: 0,
      additionOuterWall1: 0,
      additionOuterWall2: 0,
      additionOuterWall3: 0,
      additionOuterWall4: 0,
      additionUnheatedAbove: 0,
      additionUnheatedUnder: 0,
      additionWallMount: 0,
      additionWindowNoInsulation: 0,
      altitudeAdditionFactor: 0,
      altitudeThresholdForAdditions: 0,
      factorWindowPerSqm: 0,
      transformationDeviation: 0,
      work: 0,
      workPerInframeshSegment: 0,
      heatinghours_basic: 7,
      heatinghours_medium: 7,
      heatinghours_premium: 7,
      heatinghours_konvektor: 7,
      heatinghours_irpanel: 7,
      heatinghours_infrafloor_parkett: 7,
      heatinghours_infrafloor_fliesen: 7,
      heatinghours_infrafloor_estrich: 7
    });
  }

  createBuildingType() {
    const b: BuildingType = new BuildingType();
    b.name = this.buildingTypeForm.get('name').value;
    b.adaptionBedRoom = this.buildingTypeForm.get('adaptionBedRoom').value;
    b.adaptionHallway = this.buildingTypeForm.get('adaptionHallway').value;
    b.adaptionLeisureRoom = this.buildingTypeForm.get('adaptionLeisureRoom').value;
    b.adaptionSideRoom = this.buildingTypeForm.get('adaptionSideRoom').value;
    b.adaptionWetRoom = this.buildingTypeForm.get('adaptionWetRoom').value;
    b.additionIRPanels = this.buildingTypeForm.get('additionIRPanels').value;
    b.additionMoreThanFactorWindowPerSqm = this.buildingTypeForm.get('additionMoreThanFactorWindowPerSqm').value;
    b.additionOuterWall1 = this.buildingTypeForm.get('additionOuterWall1').value;
    b.additionOuterWall2 = this.buildingTypeForm.get('additionOuterWall2').value;
    b.additionOuterWall3 = this.buildingTypeForm.get('additionOuterWall3').value;
    b.additionOuterWall4 = this.buildingTypeForm.get('additionOuterWall4').value;
    b.additionUnheatedAbove = this.buildingTypeForm.get('additionUnheatedAbove').value;
    b.additionUnheatedUnder = this.buildingTypeForm.get('additionUnheatedUnder').value;
    b.additionWallMount = this.buildingTypeForm.get('additionWallMount').value;
    b.additionWindowNoInsulation = this.buildingTypeForm.get('additionWindowNoInsulation').value;
    b.altitudeAdditionFactor = this.buildingTypeForm.get('altitudeAdditionFactor').value;
    b.altitudeThresholdForAdditions = this.buildingTypeForm.get('altitudeThresholdForAdditions').value;
    b.factorWindowPerSqm = this.buildingTypeForm.get('factorWindowPerSqm').value;
    b.transformationDeviation = this.buildingTypeForm.get('transformationDeviation').value;
    b.work = this.buildingTypeForm.get('work').value;
    b.workPerInframeshSegment = this.buildingTypeForm.get('workPerInframeshSegment').value;

    b.heatinghours_basic = this.buildingTypeForm.get('heatinghours_basic').value;
    b.heatinghours_medium = this.buildingTypeForm.get('heatinghours_medium').value;
    b.heatinghours_premium = this.buildingTypeForm.get('heatinghours_premium').value;
    b.heatinghours_konvektor = this.buildingTypeForm.get('heatinghours_konvektor').value;
    b.heatinghours_irpanel = this.buildingTypeForm.get('heatinghours_irpanel').value;
    b.heatinghours_infrafloor_parkett = this.buildingTypeForm.get('heatinghours_infrafloor_parkett').value;
    b.heatinghours_infrafloor_fliesen = this.buildingTypeForm.get('heatinghours_infrafloor_fliesen').value;
    b.heatinghours_infrafloor_estrich = this.buildingTypeForm.get('heatinghours_infrafloor_estrich').value;

    this.store.dispatch(new BuildingTypeCreate(b));
    this.showCreateBuildingType = false;
    this.uiService.dispatchNotification(SnackbarNotification.getInfoMessage(b.name + ' erfolgreich gespeichert.'));

  }

}
