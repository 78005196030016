import { Component, OnInit } from '@angular/core';
import {Store} from "@ngrx/store";
import {SetTitle} from "../../actions/ui.actions";
import * as fromRoot from "../../reducers";


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(private store: Store<fromRoot.State>) { }

  ngOnInit() {
    this.store.dispatch(new SetTitle('Einstellungen'));
  }

}
