import {Injectable} from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../app.constants';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Offer} from '../models/offer';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends BaseService<Offer> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_PROJECT_OFFFERS);
  }

  allForProject(projectId: string): Observable<Offer[]> {
    return this.http.get<Offer[]>(
      environment.baseUrl +
      Constants.API_URL +
      Constants.API_URL_PROJECT +
      '/' + projectId + '/offers');
  }

  allForPartner(partnerId: string): Observable<Offer[]> {
    return this.http.get<Offer[]>(
      environment.baseUrl +
      Constants.API_URL +
      '/offers/allForPartner/' + partnerId);
  }

  allForCustomer(customerId: string): Observable<Offer[]> {
    return this.http.get<Offer[]>(
        environment.baseUrl +
        Constants.API_URL +
        '/offers/allForCustomer/' + customerId);
  }
}
