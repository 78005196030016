import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {MatDialog} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Partner} from '../../../models/Partner';
import {getOwnUser} from '../../../selectors/user.selector';
import {pipe} from 'rxjs';
import {getPartnerStateData, getPartnerStateIsLoading} from '../../../selectors/partner.selector';
import {PartnerLoad, PartnerUpdate, PartnerUpdateForm} from '../../../actions/partner.actions';
import {SetTitle} from '../../../actions/ui.actions';

@Component({
  selector: 'my-partner',
  templateUrl: './my-partner.component.html',
  styleUrls: ['./my-partner.component.scss']
})
export class MyPartnerComponent implements OnInit {
  partner: Partner;
  isLoading$;
  editPartner = false;

  partnerForm: FormGroup;

  fileToUpload: File = null;


  constructor(private store: Store<fromRoot.State>, private dialog: MatDialog, private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.partnerForm = this.buildForm(null);

    this.isLoading$ = this.store.select(pipe(getPartnerStateIsLoading));

    this.store.select(pipe(getOwnUser)).subscribe(user => {
      this.store.select(pipe(getPartnerStateData)).subscribe(partners => {
        this.partner = partners.find(p => p.id === user.partner);
        this.partnerForm = this.buildForm(this.partner);
      });

      this.store.dispatch(new PartnerLoad(user.partner));
    });

    //this.store.dispatch(new UserOwnLoad()); TODO: why does this redirect to /customers

    this.store.dispatch(new SetTitle('Mein Partner'));

  }

  buildForm(partner: Partner | null) {
    return this.formBuilder.group({
      partnerNumber: [partner ? partner.partnerNumber : ''],
      companyName: [partner ? partner.companyName : ''],
      uid: [partner ? partner.uid : ''],
      registerNumber: [partner ? partner.registerNumber : ''],
      street: [partner ? partner.street : ''],
      zipCode: [partner ? partner.zipCode : ''],
      city: [partner ? partner.city : ''],
      country: [partner ? partner.country : 'Österreich'],
      email: [partner ? partner.email : ''],
      website: [partner ? partner.website : ''],
      phone: [partner ? partner.phone : ''],
      bankName: [partner ? partner.bankName : ''],
      bankIban: [partner ? partner.bankIban : ''],
      bankBic: [partner ? partner.bankBic : ''],
      offerIntro: [partner ? partner.offerIntro : ''],
      offerTerms: [partner ? partner.offerTerms : '']
    });
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  updatePartner() {

    this.partner.companyName = this.partnerForm.get('companyName').value;
    this.partner.uid = this.partnerForm.get('uid').value;
    this.partner.registerNumber = this.partnerForm.get('registerNumber').value;
    this.partner.street = this.partnerForm.get('street').value;
    this.partner.zipCode = this.partnerForm.get('zipCode').value;
    this.partner.city = this.partnerForm.get('city').value;
    this.partner.country = this.partnerForm.get('country').value;
    this.partner.email = this.partnerForm.get('email').value;
    this.partner.website = this.partnerForm.get('website').value;
    this.partner.phone = this.partnerForm.get('phone').value;
    this.partner.bankName = this.partnerForm.get('bankName').value;
    this.partner.bankIban = this.partnerForm.get('bankIban').value;
    this.partner.bankBic = this.partnerForm.get('bankBic').value;
    this.partner.offerIntro = this.partnerForm.get('offerIntro').value;
    this.partner.offerTerms = this.partnerForm.get('offerTerms').value;

    const formData = new FormData();
    if (this.fileToUpload !== null) {
      formData.append('logo', this.fileToUpload);
    }
    formData.append('uid', this.partner.uid);
    formData.append('registerNumber', this.partner.registerNumber);
    formData.append('street', this.partner.street);
    formData.append('zipCode', this.partner.zipCode);
    formData.append('city', this.partner.city);
    formData.append('country', this.partner.country);
    formData.append('email', this.partner.email);
    formData.append('website', this.partner.website);
    formData.append('phone', this.partner.phone);
    formData.append('id', this.partner.id);
    formData.append('bankName', this.partner.bankName);
    formData.append('bankIban', this.partner.bankIban);
    formData.append('bankBic', this.partner.bankBic);
    formData.append('offerIntro', this.partner.offerIntro);
    formData.append('offerTerms', this.partner.offerTerms);
    formData.append('companyName', this.partner.companyName);

    this.store.dispatch(new PartnerUpdateForm(formData));
    this.editPartner = false;
  }
}
