import {BaseModel} from './base.model';

export class ProjectProduct implements BaseModel {
  id: string;
  project: string;
  sku: string;
  shortText: string;
  price: number;
  amount: number;
  imageUrl: string;
  longText: string;
}
