import {Partner} from '../models/Partner';
import {PartnerActions, PartnerActionTypes} from '../actions/partner.actions';
import {ProductActionTypes} from '../actions/product.actions';

export interface PartnerState {
  isLoading: boolean;
  selectedPartner: Partner;
  partners: Partner[];
}

export const inititalState: PartnerState = {
  isLoading: false,
  selectedPartner: undefined,
  partners: []
};

export function reducer(
  state = inititalState,
  action: PartnerActions
): PartnerState {
  switch (action.type) {
    case PartnerActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case PartnerActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        partners: action.payload
      };
    }

    case PartnerActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case PartnerActionTypes.SINGLE_LOADED: {
      let partners = Object.assign([], state.partners);

      if (partners.length > 0) {
        const partner = partners.find(p => p.id === action.payload.id);

        if (partner) {
          partners.splice(partners.indexOf(partner), 1);
        }
      } else {
        partners = [];
      }

      partners.push(action.payload);

      return {
        ...state,
        isLoading: false,
        partners: partners
      };
    }

    case PartnerActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case PartnerActionTypes.UPDATE_SEND_FORM: {
      return {
        ...state,
        isLoading: true
      };
    }

    case PartnerActionTypes.UPDATE_DONE: {
      let partners = Object.assign([], state.partners);

      if (partners.length > 0) {
        const updatedPartners = partners.filter(p => p.id === action.payload.id);

        if (updatedPartners) {
          updatedPartners.forEach(p => partners.splice(partners.indexOf(p), 1));
        }
      } else {
        partners = [];
      }

      partners.push(action.payload);

      return {
        ...state,
        isLoading: false,
        partners: partners
      };
    }

    case PartnerActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case PartnerActionTypes.CREATE_DONE: {
      let partners = Object.assign([], state.partners);

      if (partners.length > 0) {
        const updatedPartners = partners.filter(p => p.id === action.payload.id);

        if (updatedPartners) {
          updatedPartners.forEach(p => partners.splice(partners.indexOf(p), 1));
        }
      } else {
        partners = [];
      }

      partners.push(action.payload);

      return {
        ...state,
        isLoading: false,
        partners: partners
      };
    }

    default: {
      return state;
    }
  }
}
