import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {CustomerActionTypes} from '../actions/customer.actions';
import {CustomerService} from '../services/customer.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

@Injectable()
export class CustomerEffects {
  @Effect()
  loadCustomers$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.ALL_LOAD),
      mergeMap(() => this.customerService.all()
        .pipe(
          map(customers => ({type: CustomerActionTypes.ALL_LOADED, payload: customers})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  loadCustomer$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.customerService.get((action as any).payload)
        .pipe(
          map(customer => ({type: CustomerActionTypes.SINGLE_LOADED, payload: customer})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  assignPartner$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.PARTNER_ASSIGN),
      mergeMap((action) => this.customerService.assignPartner((action as any).payload.customer, (action as any).payload.partner)
        .pipe(
          map(customer => ({type: CustomerActionTypes.PARTNER_ASSIGNED, payload: customer})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createCustomer$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.CREATE_SEND),
      mergeMap((action) => this.customerService.create((action as any).payload)
        .pipe(
          map(customer => ({type: CustomerActionTypes.CREATE_DONE, payload: customer})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updateCustomer$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.UPDATE_SEND),
      mergeMap((action) => this.customerService.update((action as any).payload)
        .pipe(
          map(customer => ({type: CustomerActionTypes.UPDATE_DONE, payload: customer})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  loadUnassignedCustomers$ = this.actions$
    .pipe(
      ofType(CustomerActionTypes.ALL_UNASSIGNED_LOAD),
      mergeMap(() => this.customerService.allUnassigned()
        .pipe(
          map(customers => ({type: CustomerActionTypes.ALL_UNASSIGNED_LOADED, payload: customers})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private customerService: CustomerService
  ) {
  }
}
