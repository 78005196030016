import {InframeshMountingStyle} from '../models/inframesh-mounting-style.model';
import {InframeshMountingStyleActions, InframeshMountingStyleActionTypes} from '../actions/inframesh-mounting-style.actions';

export interface InframeshMountingStyleState {
  isLoading: Boolean;
  selectediInframeshMountingStyle: InframeshMountingStyle;
  inframeshMountingStyles: InframeshMountingStyle[];
}

export const inititalState: InframeshMountingStyleState = {
  isLoading: false,
  selectediInframeshMountingStyle: undefined,
  inframeshMountingStyles: []
};

export function reducer(
  state = inititalState,
  action: InframeshMountingStyleActions
): InframeshMountingStyleState {
  switch (action.type) {
    case InframeshMountingStyleActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case InframeshMountingStyleActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        inframeshMountingStyles: action.payload
      };
    }

    case InframeshMountingStyleActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case InframeshMountingStyleActionTypes.SINGLE_LOADED: {
      let inframeshMountingStyles = state.inframeshMountingStyles;

      if (inframeshMountingStyles.length > 0) {
        if (inframeshMountingStyles.filter(c => c.id === action.payload.id).length > 0) {
          inframeshMountingStyles.push(action.payload);
        }
      } else {
        inframeshMountingStyles = [];
        inframeshMountingStyles.push(action.payload);
      }

      return {
        ...state,
        isLoading: false,
        inframeshMountingStyles: inframeshMountingStyles
      };
    }

    case InframeshMountingStyleActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case InframeshMountingStyleActionTypes.CREATE_DONE: {
      const inframeshMountingStyles = Object.assign([], state.inframeshMountingStyles);

      inframeshMountingStyles.push(action.payload);

      return {
        ...state,
        isLoading: false,
        inframeshMountingStyles: inframeshMountingStyles
      };
    }

    case InframeshMountingStyleActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case InframeshMountingStyleActionTypes.UPDATE_DONE: {
      let inframeshMountingStyles = Object.assign([], state.inframeshMountingStyles);

      if (inframeshMountingStyles.length > 0) {
        const updatedInframeshMountingStyles = inframeshMountingStyles.filter(p => p.id === action.payload.id);

        if (updatedInframeshMountingStyles) {
          updatedInframeshMountingStyles.forEach(iMS => inframeshMountingStyles.splice(inframeshMountingStyles.indexOf(iMS), 1));
        }
      } else {
        inframeshMountingStyles = [];
      }

      inframeshMountingStyles.push(action.payload);

      return {
        ...state,
        isLoading: false,
        inframeshMountingStyles: inframeshMountingStyles
      };
    }

    default: {
      return state;
    }
  }
}
