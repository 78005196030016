export class Constants {
  public static readonly API_URL_BUILDINGTYPE = '/buildingTypes';
  public static readonly API_URL_CUSTOMER = '/customers';
  public static readonly API_URL_INFRAMESH_MOUNTINGTYPE = '/inframeshMountingStyles';
  public static readonly API_URL_PARTNER = '/partners';
  public static readonly API_URL_PRODUCT = '/products';
  public static readonly API_URL_PROJECT = '/projects';
  public static readonly API_URL_PROJECTROOM = '/projectRooms';
  public static readonly API_URL_PROJECTROOMTYPE = '/projectRoomTypes';
  public static readonly API_URL_USERS = '/users';
  public static readonly API_URL_PROJECT_OFFFERS = '/offers';
  public static readonly API_URL_PRODUCT_OFFFERS = '/productoffers';
  public static readonly API_URL_FILE_DOWLOADS = '/file-downloads';

  public static readonly API_URL_CREATE = '/create';
  public static readonly API_URL_ALL = '/all';
  public static readonly API_URL_UPDATE = '/update';
  public static readonly API_URL_DELETE = '/delete';

  public static readonly API_URL = '/api';
  public static readonly API_OAUTH = '/oauth';
}
