import {ProjectRoomType} from '../models/project-room-type.model';
import {ProjectRoomTypeActions, ProjectRoomTypeActionTypes} from '../actions/project-room-type.actions';
import {CustomerActionTypes} from "../actions/customer.actions";
import {InframeshMountingStyleActionTypes} from "../actions/inframesh-mounting-style.actions";

export interface ProjectRoomTypeState {
  isLoading: Boolean;
  projectRoomTypes: ProjectRoomType[];
}

export const inititalState: ProjectRoomTypeState = {
  isLoading: false,
  projectRoomTypes: []
};

export function reducer(
  state = inititalState,
  action: ProjectRoomTypeActions
): ProjectRoomTypeState {
  switch (action.type) {
    case ProjectRoomTypeActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomTypeActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        projectRoomTypes: action.payload
      };
    }

    case ProjectRoomTypeActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomTypeActionTypes.SINGLE_LOADED: {
      let projectRoomTypes = state.projectRoomTypes;

      if (projectRoomTypes.length > 0) {
        if (projectRoomTypes.filter(c => c.id === action.payload.id).length > 0) {
          projectRoomTypes.push(action.payload);
        }
      } else {
        projectRoomTypes = [];
        projectRoomTypes.push(action.payload);
      }

      return {
        ...state,
        isLoading: false,
        projectRoomTypes: projectRoomTypes
      };
    }

    case ProjectRoomTypeActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomTypeActionTypes.CREATE_DONE: {
      const projectRoomTypes = Object.assign([], state.projectRoomTypes);

      projectRoomTypes.push(action.payload);

      return {
        ...state,
        isLoading: false,
        projectRoomTypes: projectRoomTypes
      };
    }

    case ProjectRoomTypeActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomTypeActionTypes.UPDATE_DONE: {
      let projectRoomTypes = Object.assign([], state.projectRoomTypes);

      if (projectRoomTypes.length > 0) {
        const updatedProjectRoomTypes = projectRoomTypes.filter(p => p.id === action.payload.id);

        if (updatedProjectRoomTypes) {
          updatedProjectRoomTypes.forEach(pRT => projectRoomTypes.splice(projectRoomTypes.indexOf(pRT), 1));
        }
      } else {
        projectRoomTypes = [];
      }

      projectRoomTypes.push(action.payload);

      return {
        ...state,
        isLoading: false,
        projectRoomTypes: projectRoomTypes
      };
    }

    default: {
      return state;
    }
  }
}
