import {Action} from '@ngrx/store';
import {Customer} from '../models/Customer';
import {Partner} from '../models/Partner';

export enum CustomerActionTypes {
  ALL_LOAD = '[Customer] All Loading...',
  ALL_LOADED = '[Customer] All Loaded.',
  SINGLE_LOAD = '[Customer] Single Loading...',
  SINGLE_LOADED = '[Customer] Single Loaded.',
  CREATE_SEND = '[Customer] creating...',
  CREATE_DONE = '[Customer] created.',
  UPDATE_SEND = '[Customer] updating...',
  UPDATE_DONE = '[Customer] updated.',
  ALL_UNASSIGNED_LOAD = '[Customer] all unassigned loading...',
  ALL_UNASSIGNED_LOADED = '[Customer] all unassigned loaded.',
  PARTNER_ASSIGN = '[Customer] assigning partner...',
  PARTNER_ASSIGNED = '[Customer] partner assigned.'
}

export class CustomersLoad implements Action {
  readonly type = CustomerActionTypes.ALL_LOAD;
}

export class CustomersLoaded implements Action {
  readonly type = CustomerActionTypes.ALL_LOADED;

  constructor(public payload: Customer[]) {
  }
}

export class CustomersUnassignedLoad implements Action {
  readonly type = CustomerActionTypes.ALL_UNASSIGNED_LOAD;
}

export class CustomersUnassignedLoaded implements Action {
  readonly type = CustomerActionTypes.ALL_UNASSIGNED_LOADED;

  constructor(public payload: Customer[]) {
  }
}

export class CustomerLoad implements Action {
  readonly type = CustomerActionTypes.SINGLE_LOAD;

  constructor(public payload: string) {
  }
}

export class CustomerLoaded implements Action {
  readonly type = CustomerActionTypes.SINGLE_LOADED;

  constructor(public payload: Customer) {
  }
}

export class CustomerCreate implements Action {
  readonly type = CustomerActionTypes.CREATE_SEND;

  constructor(public payload: Customer) {
  }
}

export class CustomerCreated implements Action {
  readonly type = CustomerActionTypes.CREATE_DONE;

  constructor(public payload: Customer) {
  }
}

export class CustomerUpdate implements Action {
  readonly type = CustomerActionTypes.UPDATE_SEND;

  constructor(public payload: Customer) {
  }
}

export class CustomerUpdated implements Action {
  readonly type = CustomerActionTypes.UPDATE_DONE;

  constructor(public payload: Customer) {
  }
}

export class CustomerPartnerAssign implements Action {
  readonly type = CustomerActionTypes.PARTNER_ASSIGN;

  constructor(public payload: { customer: Customer, partner: Partner }) {
  }
}

export class CustomerPartnerAssigned implements Action {
  readonly type = CustomerActionTypes.PARTNER_ASSIGNED;

  constructor(public payload: Customer) {
  }
}

export type CustomerActions =
  CustomersLoad
  | CustomersLoaded
  | CustomersUnassignedLoad
  | CustomersUnassignedLoaded
  | CustomerLoad
  | CustomerLoaded
  | CustomerCreate
  | CustomerCreated
  | CustomerUpdate
  | CustomerUpdated
  | CustomerPartnerAssign
  | CustomerPartnerAssigned
  ;
