import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Offer} from '../../models/offer';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {User} from '../../models/user.model';
import {Observable, pipe} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {areOffersLoading, getOffers} from '../../selectors/offer.selector';
import {OffersForPartnerLoad} from '../../actions/offer.actions';
import {getOwnUser} from '../../selectors/user.selector';
import {Project} from '../../models/Project';
import {Customer} from '../../models/Customer';
import {getCustomerStateData} from '../../selectors/customer.selector';
import {CustomersLoad} from '../../actions/customer.actions';
import {getProjects} from '../../selectors/project.selector';
import {ProjectsLoad} from '../../actions/project.actions';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
  offers: Offer[];
  dataSource = null;
  columnsToDisplay = ['number'];
  isLoading$;
  resultsLength = 0;
  showCreateProduct = false;
  productForm: FormGroup;
  originalData = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: User;
  filteredOptions: Observable<any[]>;

  customers: Customer[];
  projects: Project[];

  constructor(private http: HttpClient, private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.store.select(pipe(getOwnUser)).subscribe(user => {
      this.user = user;
      this.store.dispatch(new OffersForPartnerLoad((user.partner)));

    });

    this.store.select(pipe(getCustomerStateData)).subscribe(customers => {
      this.customers = customers;
    });

    this.store.dispatch(new CustomersLoad());

    this.store.select(pipe(getProjects)).subscribe(projects => {
      this.projects = projects;
    });

    this.store.dispatch(new ProjectsLoad());

    this.store.select(pipe(getOffers)).subscribe(offers => {
      this.offers = offers.filter(o => o.isValid || o.isFinal);
      this.dataSource = new MatTableDataSource<Offer>(this.offers);
      this.resultsLength = this.offers.length;
      setTimeout(() => this.dataSource.paginator = this.paginator, 300);
    });


    this.isLoading$ = this.store.select(pipe(areOffersLoading));
  }

  getCustomerName(id) {
    if (this.customers && this.projects && this.customers.length > 0 && this.projects.length > 0) {
      const customerId = this.projects.find(p => p.id === id).customer;
      const customer = this.customers.find(c => c.id === customerId);


      return customer.companyName ? customer.companyName : customer.firstname + ' ' + customer.surname;
    } else {
      return '';
    }
  }

  getProjectName(id) {
    if (this.projects && this.projects.length > 0) {
      return this.projects.find(p => p.id === id).name;
    } else {
      return '';
    }
  }

  exportOffers() {
    this.http.get("/api/exportOffers", {responseType:  'blob'}) .subscribe(
        (response) => {                           //next() callback
          console.log('response received');
          console.log(response);

          var saveData = (function () {
            var a = document.createElement("a");
            document.body.appendChild(a);
            //a.style = "display: none";
            return function (data, fileName) {
                  var url = window.URL.createObjectURL(data);
              a.href = url;
              a.download = fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            };
          }());

          saveData(response, "SH.xlsx");

        },
        (error) => {                              //error() callback
          console.error('Request failed with error ' + error);
          console.log(error);

          //this.loading = false;
        },
        () => {                                   //complete() callback
          console.error('Request completed')      //This is actually not needed
          //this.loading = false;
        });
    console.log("OK");
  }

  applyFilterOffers(filterValue: string) {


    for(var x in this.dataSource.data)
    {

      //console.log(this.getCustomerName(this.dataSource.data[x].id));


    }

    var filteredCust = this.customers.filter(function(a){
      if(a.companyName)
      {
        return a.companyName.toLowerCase().indexOf(filterValue.toLowerCase().trim()) > -1;
      }

      if(a.firstname)
      {
        return (a.firstname + ' ' + a.surname).toLowerCase().indexOf(filterValue.toLowerCase().trim()) > -1;
      }

    });

    var filteredProj = this.projects.filter(function(a){
        return (a.name).toLowerCase().indexOf(filterValue.toLowerCase().trim()) > -1;
    });

    //window.filteredCustomers = filteredCust;
    //window.filteredProjects = filteredProj;

    var filteredProjIds = [];
    var filteredCustomerIds = [];
    var projectToCustomer = {};

    for(var p in this.projects)
    {
      projectToCustomer[this.projects[p].id] = this.projects[p].customer;
    }

    for(var p in filteredProj)
    {
      filteredProjIds.push(filteredProj[p].id);

    }

    for(var c in filteredCust)
    {
      filteredCustomerIds.push(filteredCust[c].id);
    }

    if(!this.originalData)
    {
      this.originalData = this.dataSource.data;
    }

    this.dataSource.data = this.originalData.filter(function(offer){
      return filteredProjIds.indexOf(offer.project) > -1 || filteredCustomerIds.indexOf(projectToCustomer[offer.project]) > -1;
    });


    //this.dataSource._updateChangeSubscription();
  }
}
