import {Action} from '@ngrx/store';
import {Offer} from '../models/offer';

export enum OfferActionTypes {
  ALL_LOAD = '[Offer] All Loading...',
  ALL_LOADED = '[Offer] All Loaded.',
  SINGLE_LOAD = '[Offer] Single Loading...',
  SINGLE_LOADED = '[Offer] Single Loaded.',
  CREATE_SEND = '[Offer] creating....',
  CREATE_DONE = '[Offer] created.',
  ALL_FOR_PROJECT_LOAD = '[Offer] for project loading...',
  ALL_FOR_PROJECT_LOADED = '[Offer] for project loaded.',
  ALL_FOR_PARTNER_LOAD = '[Offer] for partner loading...',
  ALL_FOR_PARTNER_LOADED = '[Offer] for partner loaded.',
  ALL_FOR_CUSTOMER_LOADED = '[Offer] for customer loaded.',
  ALL_FOR_CUSTOMER_LOAD = '[Offer] for customer loading.'
}

export class OfferLoad implements Action {
  readonly type = OfferActionTypes.SINGLE_LOAD;

  constructor(public payload: string) {
  }
}

export class OfferLoaded implements Action {
  readonly type = OfferActionTypes.SINGLE_LOADED;

  constructor(public payload: Offer) {
  }
}

export class OffersLoad implements Action {
  readonly type = OfferActionTypes.ALL_LOAD;
}

export class OffersLoaded implements Action {
  readonly type = OfferActionTypes.ALL_LOADED;

  constructor(public payload: Offer[]) {
  }
}

export class OfferCreate implements Action {
  readonly type = OfferActionTypes.CREATE_SEND;

  constructor(public payload: Offer) {
  }
}

export class OfferCreated implements Action {
  readonly type = OfferActionTypes.CREATE_DONE;

  constructor(public payload: Offer) {
  }
}

export class OffersForProjectLoad implements Action {
  readonly type = OfferActionTypes.ALL_FOR_PROJECT_LOAD;

  constructor(public payload: string) {
  }
}

export class OffersForCustomerLoaded implements Action {
  readonly type = OfferActionTypes.ALL_FOR_CUSTOMER_LOADED;

  constructor(public payload: Offer[]) {
  }
}

export class OffersForCustomerLoad implements Action {
  readonly type = OfferActionTypes.ALL_FOR_CUSTOMER_LOAD;

  constructor(public payload: string) {
  }
}

export class OffersForProjectLoaded implements Action {
  readonly type = OfferActionTypes.ALL_FOR_PROJECT_LOADED;

  constructor(public payload: Offer[]) {
  }
}

export class OffersForPartnerLoad implements Action {
  readonly type = OfferActionTypes.ALL_FOR_PARTNER_LOAD;

  constructor(public payload: string) {
  }
}

export class OffersForPartnerLoaded implements Action {
  readonly type = OfferActionTypes.ALL_FOR_PARTNER_LOADED;

  constructor(public payload: Offer[]) {
  }
}

export type OfferActions =
  OfferLoad
  | OfferLoaded
  | OffersLoad
  | OffersLoaded
  | OfferCreate
  | OfferCreated
  | OffersForProjectLoad
  | OffersForProjectLoaded
  | OffersForPartnerLoad
  | OffersForPartnerLoaded
    | OffersForCustomerLoad
    | OffersForCustomerLoaded
  ;
