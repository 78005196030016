import {Action} from '@ngrx/store';
import {ProjectRoom} from '../models/project-room.model';

export enum ProjectRoomActionTypes {
  ALL_LOAD = '[ProjectRoom] All Loading...',
  ALL_LOADED = '[ProjectRoom] All Loaded.',
  CREATE_SEND = '[ProjectRoom] creating...',
  CREATE_DONE = '[ProjectRoom] created.',
  UPDATE_SEND = '[ProjectRoom] updating...',
  UPDATE_DONE = '[ProjectRoom] updated.',
  DELETE_SEND = '[ProjectRoom] deleting...',
  DELETE_DONE = '[ProjectRoom] deleted.'
}

export class ProjectRoomLoad implements Action {
  readonly type = ProjectRoomActionTypes.ALL_LOAD;
}

export class ProjectRoomLoaded implements Action {
  readonly type = ProjectRoomActionTypes.ALL_LOADED;

  constructor(public payload: ProjectRoom[]) {
  }
}

export class ProjectRoomCreate implements Action {
  readonly type = ProjectRoomActionTypes.CREATE_SEND;

  constructor(public payload: ProjectRoom) {
  }
}

export class ProjectRoomCreated implements Action {
  readonly type = ProjectRoomActionTypes.CREATE_DONE;

  constructor(public payload: ProjectRoom) {
  }
}

export class ProjectRoomUpdate implements Action {
  readonly type = ProjectRoomActionTypes.UPDATE_SEND;

  constructor(public payload: ProjectRoom) {
  }
}

export class ProjectRoomUpdated implements Action {
  readonly type = ProjectRoomActionTypes.UPDATE_DONE;

  constructor(public payload: ProjectRoom) {
  }
}

export class ProjectRoomDelete implements Action {
  readonly type = ProjectRoomActionTypes.DELETE_SEND;

  constructor(public payload: string) {
  }
}

export class ProjectRoomDeleted implements Action {
  readonly type = ProjectRoomActionTypes.DELETE_DONE;

  constructor(public payload: string) {
  }
}

export type ProjectRoomActions =
  ProjectRoomLoad
  | ProjectRoomLoaded
  | ProjectRoomCreate
  | ProjectRoomCreated
  | ProjectRoomUpdate
  | ProjectRoomUpdated
  | ProjectRoomDelete
  | ProjectRoomDeleted;
