import { Component, OnInit, Input, HostBinding } from '@angular/core';
import {coerceBooleanProperty} from '@angular/cdk/coercion';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  private hostValue: boolean;
  get inverted(): boolean {
    return this.hostValue;
  }

  @Input()
  set inverted(value: boolean) {
    this.hostValue = coerceBooleanProperty(value);
  }

  @HostBinding('class.inverted') invertedClass = false;

  constructor() { }

  ngOnInit() {
    this.invertedClass = this.inverted;
  }

}
