import {User} from '../models/user.model';
import {UserActions, UserActionTypes} from '../actions/user.actions';
import {ProjectActionTypes} from '../actions/project.actions';

export interface UserState {
  isLoading: boolean;
  ownUser: User;
  accessToken: string;
  refreshToken: string;
  users: User[];
}

export const inititalState: UserState = {
  isLoading: false,
  ownUser: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  users: undefined
};

export function reducer(
  state = inititalState,
  action: UserActions
): UserState {
  switch (action.type) {
    case UserActionTypes.DO_LOGIN: {
      return {
        ...state,
        isLoading: true
      };
    }

    case UserActionTypes.DO_LOGIN_ERROR: {
      return {
        ...state,
        isLoading: false
      };
    }

    case UserActionTypes.LOGGED_IN: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        isLoading: false
      };
    }

    case UserActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case UserActionTypes.CREATE_DONE: {
      let users = Object.assign([], state.users);

      if (users.length > 0) {
        const updatedUsers = users.filter(p => p.id === action.payload.id);

        if (updatedUsers) {
          updatedUsers.forEach(iMS => users.splice(users.indexOf(iMS), 1));
        }
      } else {
        users = [];
      }

      users.push(action.payload);

      return {
        ...state,
        isLoading: false,
        users: users
      };
    }

    case UserActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case UserActionTypes.UPDATE_DONE: {
      let users = Object.assign([], state.users);

      if (users.length > 0) {
        const updatedUsers = users.filter(p => p.id === action.payload.id);

        if (updatedUsers) {
          updatedUsers.forEach(iMS => users.splice(users.indexOf(iMS), 1));
        }
      } else {
        users = [];
      }

      users.push(action.payload);

      return {
        ...state,
        isLoading: false,
        users: users
      };
    }

    case UserActionTypes.OWN_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case UserActionTypes.OWN_LOADED: {
      let users = Object.assign([], state.users);

      if (users.length > 0) {
        const updatedUsers = users.filter(p => p.id === action.payload.id);

        if (updatedUsers) {
          updatedUsers.forEach(iMS => users.splice(users.indexOf(iMS), 1));
        }
      } else {
        users = [];
      }

      users.push(action.payload);

      return {
        ...state,
        isLoading: false,
        ownUser: action.payload,
        users: users
      };
    }

    case UserActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: false
      };
    }

    case UserActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        users: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
