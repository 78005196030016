export class ProjectRoom {
  id: string;
  parentRoom: string;
  project: string;
  roomType: string;
  name: string;
  floor: number;
  size: number;
  height: number;
  numberWindows: number;
  windowsInsulated: boolean;
  subFloorUnheated: boolean;
  supFloorUnheated: boolean;
  plasterInsteadCeiling: boolean;
  numberOuterWalls: number;
  numberDoorsInside: number;
  mountingStyle: string;
  IM440S: number;
  IM530FB: number;
  IM530S: number;
  IM790T: number;
  IM900S: number;
  SimplySlim180: number;
  SimplySlim360: number;
  SimplySlim540: number;
  SimplySlim720: number;
  SimplySlim840: number;
  SimplySlim1020: number;
  additionsAboveUnder: number;
  additionsOuterWalls: number;
  additionsRoomTypes: number;
  additionsWindowsPerSqm: number;
  cupboardIRPanels: number;
  cupboardPrio1: number;
  cupboardPrio2: number;
  floorSensors: number;
  packing: number;
  priorityRoomType: number;
  roomSensors: number;
  subSumWithAdditions: number;
  subTotal: number;
  volume: number;
  workWithAdditions: number;
  workWithClimate: number;
  workByClient: number;
  created_at: string;
  subRoomCreate: string;
  hasWellness: boolean;
  hasFussboden: boolean;
}
