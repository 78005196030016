import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Constants} from '../app.constants';
import {HttpClient} from '@angular/common/http';
import {BaseModel} from '../models/base.model';

export abstract class BaseService<T extends BaseModel> {

  constructor(protected http: HttpClient, protected entityUrl: string) {
  }

  get(id: string): Observable<T> {
    return this.http.get<T>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/' + id
    );
  }

  all(): Observable<T[]> {
    return this.http.get<T[]>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl + Constants.API_URL_ALL);
  }

  create(entity: T): Observable<T> {
    return this.http.post<T>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      Constants.API_URL_CREATE
      , entity);
  }

  update(entity: T): Observable<T> {
    return this.http.post<T>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/' + entity.id +
      Constants.API_URL_UPDATE
      , entity);
  }

  delete(entityId: string): Observable<string> {
    return this.http.post<string>(
      environment.baseUrl +
      Constants.API_URL +
      this.entityUrl +
      '/' + entityId +
      Constants.API_URL_DELETE, {});
  }
}
