import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {InframeshMountingStyleActionTypes} from '../actions/inframesh-mounting-style.actions';
import {InframeshMountingStyleService} from '../services/inframesh-mounting-style.service';

@Injectable()
export class InframeshMountingStyleEffects {

  @Effect()
  loadInframeshMountingStyles$ = this.actions$
    .pipe(
      ofType(InframeshMountingStyleActionTypes.ALL_LOAD),
      mergeMap(() => this.inframeshMountingStyleService.all()
        .pipe(
          map(inframeshMountingStyles => ({type: InframeshMountingStyleActionTypes.ALL_LOADED, payload: inframeshMountingStyles})),
          catchError(() => EMPTY)
        )
      )
    );
  @Effect()
  loadInframeshMountingStyle$ = this.actions$
    .pipe(
      ofType(InframeshMountingStyleActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.inframeshMountingStyleService.get((action as any).payload)
        .pipe(
          map(inframeshMountingStyle => ({type: InframeshMountingStyleActionTypes.SINGLE_LOADED, payload: inframeshMountingStyle})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createInframeshMountingStyle$ = this.actions$
    .pipe(
      ofType(InframeshMountingStyleActionTypes.CREATE_SEND),
      mergeMap((action) => this.inframeshMountingStyleService.create((action as any).payload)
        .pipe(
          map(inframeshMountingStyle => ({type: InframeshMountingStyleActionTypes.CREATE_DONE, payload: inframeshMountingStyle})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updateInframeshMountingStyle$ = this.actions$
    .pipe(
      ofType(InframeshMountingStyleActionTypes.UPDATE_SEND),
      mergeMap((action) => this.inframeshMountingStyleService.update((action as any).payload)
        .pipe(
          map(inframeshMountingStyle => ({type: InframeshMountingStyleActionTypes.UPDATE_DONE, payload: inframeshMountingStyle})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private inframeshMountingStyleService: InframeshMountingStyleService
  ) {
  }
}
