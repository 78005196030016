import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {ProjectActionTypes} from '../actions/project.actions';
import {ProjectService} from '../services/project.service';

@Injectable()
export class ProjectEffects {
  @Effect()
  loadProjects$ = this.actions$
    .pipe(
      ofType(ProjectActionTypes.ALL_LOAD),
      mergeMap(() => this.projectService.all()
        .pipe(
          map(projects => ({type: ProjectActionTypes.ALL_LOADED, payload: projects})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createProject$ = this.actions$
    .pipe(
      ofType(ProjectActionTypes.CREATE_SEND),
      mergeMap((action) => this.projectService.create((action as any).payload)
        .pipe(
          map(project => ({type: ProjectActionTypes.CREATE_DONE, payload: project})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updateProject$ = this.actions$
    .pipe(
      ofType(ProjectActionTypes.UPDATE_SEND),
      mergeMap((action) => this.projectService.update((action as any).payload)
        .pipe(
          map(project => ({type: ProjectActionTypes.UPDATE_DONE, payload: project})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  loadProject$ = this.actions$
    .pipe(
      ofType(ProjectActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.projectService.get((action as any).payload)
        .pipe(
          map(project => ({type: ProjectActionTypes.SINGLE_LOADED, payload: project})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  loadProducts = this.actions$
    .pipe(
      ofType(ProjectActionTypes.PROJECT_PRODUCTS_LOAD),
      mergeMap((action) => this.projectService.products((action as any).payload)
        .pipe(
          map(products => ({type: ProjectActionTypes.PROJECT_PRODUCTS_LOADED, payload: products})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createProduct$ = this.actions$
    .pipe(
      ofType(ProjectActionTypes.PROJECT_PRODUCT_CREATE),
      mergeMap((action) => this.projectService.createProduct((action as any).payload)
        .pipe(
          map(product => ({type: ProjectActionTypes.PROJECT_PRODUCT_CREATED, payload: product})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  deleteProduct$ = this.actions$
    .pipe(
      ofType(ProjectActionTypes.PROJECT_PRODUCT_DELETE),
      mergeMap((action) => this.projectService.deleteProduct((action as any).payload)
        .pipe(
          map(productId => ({type: ProjectActionTypes.PROJECT_PRODUCT_DELETED, payload: productId})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private projectService: ProjectService
  ) {
  }
}
