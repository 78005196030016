import {Project} from '../models/Project';
import {ProjectActions, ProjectActionTypes} from '../actions/project.actions';
import {ProjectProduct} from '../models/project-product.model';

export interface ProjectState {
  isLoading: Boolean;
  projects: Project[];
  products: ProjectProduct[];
}

export const inititalState: ProjectState = {
  isLoading: false,
  projects: [],
  products: []
};

export function reducer(
  state = inititalState,
  action: ProjectActions
): ProjectState {
  switch (action.type) {
    case ProjectActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        projects: action.payload
      };
    }

    case ProjectActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectActionTypes.PROJECT_PRODUCTS_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectActionTypes.PROJECT_PRODUCTS_LOADED: {
      return {
        ...state,
        isLoading: false,
        products: action.payload
      };
    }

    case ProjectActionTypes.PROJECT_PRODUCT_CREATE: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectActionTypes.PROJECT_PRODUCT_CREATED: {
      let products = Object.assign([], state.products);

      if (products.length > 0) {
        const updatedProducts = products.filter(pp => pp.id === action.payload.id);

        if (updatedProducts) {
          updatedProducts.forEach(p => products.splice(products.indexOf(p), 1));
        }
      } else {
        products = [];
      }

      products.push(action.payload);

      return {
        ...state,
        isLoading: false,
        products: products
      };
    }

    case ProjectActionTypes.PROJECT_PRODUCT_DELETED: {
      const products = Object.assign([], state.products);

      products.splice(products.indexOf(products.find(p => p.id === action.payload)), 1);

      return {
        ...state,
        isLoading: false,
        products: products
      };
    }

    case ProjectActionTypes.PROJECT_PRODUCT_DELETE: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectActionTypes.SINGLE_LOADED: {
      let projects = Object.assign([], state.projects);

      if (projects.length > 0) {
        const project = projects.find(p => p.id === action.payload.id);

        if (project) {
          projects.splice(projects.indexOf(project), 1);
        }
      } else {
        projects = [];
      }

      projects.push(action.payload);

      return {
        ...state,
        isLoading: false,
        projects: projects
      };
    }

    case ProjectActionTypes.CREATE_DONE: {
      const projects = Object.assign([], state.projects);

      projects.push(action.payload);

      return {
        ...state,
        isLoading: false,
        projects: projects
      };
    }

    case ProjectActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectActionTypes.UPDATE_DONE: {
      let projects = Object.assign([], state.projects);

      if (projects.length > 0) {
        const updatedProjects = projects.filter(p => p.id === action.payload.id);

        if (updatedProjects) {
          updatedProjects.forEach(p => projects.splice(projects.indexOf(p), 1));
        }
      } else {
        projects = [];
      }

      projects.push(action.payload);

      return {
        ...state,
        isLoading: false,
        projects: projects
      };
    }

    default: {
      return state;
    }
  }
}
