import {Actions} from '@ngrx/effects';
import {UIService} from '../services/ui.service';
import {UtilService} from '../services/util.service';
import {SnackbarNotification} from '../models/ui/snackbar-notification.model';

export class BaseEffects {
  constructor(
    protected actions$: Actions,
    protected uiService: UIService,
    protected utilService: UtilService
  ) {
  }

  handleServerError(response) {
    if (response.status === 500) {
      this.uiService.dispatchNotification(SnackbarNotification.getErrorMessage(response.message));
    }
  }
}
