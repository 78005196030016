import {Component, OnInit, Inject, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Customer} from '../../../models/Customer';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {getCustomerStateData, getCustomerStateIsLoading} from '../../../selectors/customer.selector';
import {CustomerCreate, CustomerLoad, CustomersLoad, CustomerUpdate} from '../../../actions/customer.actions';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {MatDialog} from '@angular/material';

import {SetTitle} from '../../../actions/ui.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Product} from '../../../models/product.model';
import {getOwnUser} from "../../../selectors/user.selector";
import {User} from '../../../models/user.model';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  id: string;
  customer: Customer;
  isLoading$;
  editCustomer = false;
  user: User;
  customerForm: FormGroup;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, private dialog: MatDialog, private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;
    });

    this.buildForm(null);

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.store.dispatch(new CustomerLoad(this.id));
    });

    this.store.pipe(select(getCustomerStateData)).subscribe(customers => {
      this.customer = customers.find(c => c.id === this.id);

      if (this.customer) {
        this.store.dispatch(new SetTitle('Kundenübersicht > ' + this.customer.firstname + ' ' + this.customer.surname));
        this.customerForm = this.buildForm(this.customer);
      }

    });

    this.isLoading$ = this.store.pipe(select(getCustomerStateIsLoading));
  }

  confirmDeleteCustomer(): void {
    // const dialogConfig = new MatDialogConfig();
    //     // dialogConfig.disableClose = true;
    //     // dialogConfig.autoFocus = true;
    //     // dialogConfig.position = {
    //     //   'top': '0',
    //     //   left: '0'
    //     // };
    //     //
    //     // this.dialog.open(ConfirmDeleteCustomerDialog);

  }

  toggleEditCustomer(): void {
    this.editCustomer = !this.editCustomer;
  }

  updateCustomer() {
    this.customer.firstname = this.customerForm.get('firstname').value;
    this.customer.surname = this.customerForm.get('surname').value;
    this.customer.companyName = this.customerForm.get('companyName').value;
    this.customer.street = this.customerForm.get('street').value;
    this.customer.zipCode = this.customerForm.get('zipCode').value;
    this.customer.city = this.customerForm.get('city').value;
    this.customer.country = this.customerForm.get('country').value;
    this.customer.phone = this.customerForm.get('phone').value;
    this.customer.email = this.customerForm.get('email').value;
    this.customer.user = this.customerForm.get('user').value;
    this.customer.kdrabatt = this.customerForm.get('kdrabatt').value;

    this.customer.person1_name = this.customerForm.get('person1_name').value;
    this.customer.person1_email = this.customerForm.get('person1_email').value;
    this.customer.person1_phone = this.customerForm.get('person1_phone').value;
    this.customer.person2_name = this.customerForm.get('person2_name').value;
    this.customer.person2_email = this.customerForm.get('person2_email').value;
    this.customer.person2_phone = this.customerForm.get('person2_phone').value;
    this.customer.person3_name = this.customerForm.get('person3_name').value;
    this.customer.person3_email = this.customerForm.get('person3_email').value;
    this.customer.person3_phone = this.customerForm.get('person3_phone').value;
    
    this.store.dispatch(new CustomerUpdate(this.customer));
    this.editCustomer = false;
  }

  buildForm(customer: Customer | null) {
    return this.formBuilder.group({
      firstname: [customer ? customer.firstname : '', Validators.required],
      surname: [customer ? customer.surname : '', Validators.required],
      companyName: [customer ? customer.companyName : ''],
      uid: [customer ? customer.uid : ''],
      registerNumber: [customer ? customer.registerNumber : ''],
      street: [customer ? customer.street : ''],
      zipCode: [customer ? customer.zipCode : ''],
      city: [customer ? customer.city : ''],
      country: [customer ? customer.country : 'Österreich'],
      email: [customer ? customer.email : ''],
      phone: [customer ? customer.phone : ''],
      user: [customer ? customer.user : ''],
      kdrabatt: [customer ? customer.kdrabatt : ''],

      person1_name: [customer ? customer.kdrabatt : ''],
      person1_phone: [customer ? customer.kdrabatt : ''],
      person1_email: [customer ? customer.kdrabatt : ''],
      person2_name: [customer ? customer.kdrabatt : ''],
      person2_phone: [customer ? customer.kdrabatt : ''],
      person2_email: [customer ? customer.kdrabatt : ''],
      person3_name: [customer ? customer.kdrabatt : ''],
      person3_phone: [customer ? customer.kdrabatt : ''],
      person3_email: [customer ? customer.kdrabatt : '']
    });
  }
}
