import {BuildingType} from '../models/BuildingType';
import {BuildingTypeActionTypes, BuildingTypeActions} from '../actions/building-type.actions';
import {ProjectRoomTypeActionTypes} from "../actions/project-room-type.actions";

export interface BuildingTypeState {
  isLoading: Boolean;
  selectedBuildingType: BuildingType;
  buildingTypes: BuildingType[];
}

export const inititalState: BuildingTypeState = {
  isLoading: false,
  selectedBuildingType: undefined,
  buildingTypes: []
};

export function reducer(
  state = inititalState,
  action: BuildingTypeActions
): BuildingTypeState {
  switch (action.type) {
    case BuildingTypeActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case BuildingTypeActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        buildingTypes: action.payload
      };
    }

    case BuildingTypeActionTypes.SINGLE_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case BuildingTypeActionTypes.SINGLE_LOADED: {
      let buildingTypes = state.buildingTypes;

      if (buildingTypes.length > 0) {
        if (buildingTypes.filter(c => c.id === action.payload.id).length > 0) {
          buildingTypes.push(action.payload);
        }
      } else {
        buildingTypes = [];
        buildingTypes.push(action.payload);
      }

      return {
        ...state,
        isLoading: false,
        buildingTypes: buildingTypes
      };
    }

    case BuildingTypeActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case BuildingTypeActionTypes.CREATE_DONE: {
      const buildingTypes = Object.assign([], state.buildingTypes);

      buildingTypes.push(action.payload);

      return {
        ...state,
        isLoading: false,
        buildingTypes: buildingTypes
      };
    }

    case BuildingTypeActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case BuildingTypeActionTypes.UPDATE_DONE: {
      let buildingTypes = Object.assign([], state.buildingTypes);

      if (buildingTypes.length > 0) {
        const buildingTypesBeforeUpdate = buildingTypes.filter(p => p.id === action.payload.id);

        if (buildingTypesBeforeUpdate) {
          buildingTypesBeforeUpdate.forEach(bt => buildingTypes.splice(buildingTypes.indexOf(bt), 1));
        }
      } else {
        buildingTypes = [];
      }

      buildingTypes.push(action.payload);

      return {
        ...state,
        isLoading: false,
        buildingTypes: buildingTypes
      };
    }

    default: {
      return state;
    }
  }
}
