import {Component, OnInit} from '@angular/core';
import {BuildingType} from '../../../../models/BuildingType';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import {MatDialog, MatTableDataSource} from '@angular/material';
import {BuildingTypeLoad, BuildingTypeUpdate} from '../../../../actions/building-type.actions';
import {areBuildingTypesLoading, getBuildingTypes} from '../../../../selectors/building-type.selector';
import {SetTitle} from '../../../../actions/ui.actions';
import {Product} from '../../../../models/product.model';
import {areProductsLoading, getProducts} from '../../../../selectors/product.selector';
import {ProductCreate, ProductLoad, ProductsLoad, ProductUpdate} from '../../../../actions/product.actions';

@Component({
  selector: 'product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {


  id: string;
  product: Product;
  isLoading$;
  editProduct = false;
  productForm: FormGroup;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, private dialog: MatDialog, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.buildForm(null);

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.store.dispatch(new ProductLoad(this.id));
    });

    this.store.pipe(select(getProducts)).subscribe(products => {
      this.product = products.find(p => p.id === this.id);

      if (this.product) {
        this.store.dispatch(new SetTitle('Produkte Übersicht > ' + this.product.shortText));
        this.productForm = this.buildForm(this.product);
      }
    });

    this.isLoading$ = this.store.pipe(select(areProductsLoading));
  }

  confirmDeleteBuildingType() {
    console.log('wirklich löschen?');
    //this.dialog.open(ConfirmDeleteCustomerComponent);
  }


  updateProduct() {
    this.product.sku = this.productForm.get('sku').value;
    this.product.shortText = this.productForm.get('shortText').value;
    this.product.feature = this.productForm.get('feature').value;
    this.product.unit = this.productForm.get('unit').value;
    this.product.price = this.productForm.get('price').value;
    this.product.longText = this.productForm.get('longText').value;

    this.store.dispatch(new ProductUpdate(this.product));
  }

  buildForm(product: Product | null) {
    return this.formBuilder.group({
      sku: [product ? product.sku : '', Validators.required],
      shortText: [product ? product.shortText : '', Validators.required],
      feature: [product ? product.feature : 0, Validators.required],
      unit: [product ? product.unit : '', Validators.required],
      price: [product ? product.price : 0, Validators.required],
      longText: [product ? product.longText : '']
    });
  }

}
