import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {getTitle} from '../../../selectors/ui.selector';
import {Router} from '@angular/router';
import {Logout} from '../../../actions/user.actions';
import {SnackbarNotification} from '../../../models/ui/snackbar-notification.model';
import {getOwnUser} from '../../../selectors/user.selector';
import {User} from '../../../models/user.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss']
})
export class AppContainerComponent implements OnInit {

  appTitle$;
  role = 'admin';
  user: User;
  showMenu: Boolean = false;
  background = false;

  constructor(private router: Router, private store: Store<fromRoot.State>) {
  }

  ngOnInit() {
    this.appTitle$ = this.store.pipe(select(getTitle));
    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;
    });

    this.background = environment.backgroundColor;
  }

  logout() {
    this.store.dispatch(new Logout(SnackbarNotification.getInfoMessage('')));
    this.router.navigate(['/login']);
  }
}
