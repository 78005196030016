import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, of, pipe} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {PartnerUpdateForm} from '../../actions/partner.actions';
import {DownloadFileCreateWithForm, DownloadFileDelete, DownloadFilesLoad} from '../../actions/download.actions';
import {areDownloadFilesLoading, getDownloadFiles} from '../../selectors/download-file.selector';
import {DownloadFile} from '../../models/download.file.model';
import {getOwnUser} from '../../selectors/user.selector';
import {User} from '../../models/user.model';
import {OfferOverlayComponent} from '../customers/customer-detail/project-detail/offer-overlay/offer-overlay.component';
import {OffersForProjectLoad} from '../../actions/offer.actions';
import {MatDialog} from '@angular/material';
import {DeleteConfirmComponent} from './delete-confirm/delete-confirm.component';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit {

  fileToUpload: File = null;
  uploadControl = new FormControl();
  fileForm: FormGroup;
  filteredCategories: Observable<string[]>;
  isLoading$;
  categories: string[] = [];
  files: DownloadFile[][];
  user: User;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.store.select(pipe(getOwnUser)).subscribe(user => {
      this.user = user;
    });

    this.isLoading$ = this.store.select(pipe(areDownloadFilesLoading));

    this.store.select(pipe(getDownloadFiles)).subscribe(files => {
      files = files.filter(f => f.project === null || f.project === undefined);
      const cats = new Set();

      files.forEach(f => cats.add(f.category)); // TODO find a better, more efficient way to do this.

      this.categories = Array.from(cats).sort(function (a, b) {
        const textA = a.toLowerCase();
        const textB = b.toLowerCase();
        return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
      });

      this.files = [];
      cats.forEach(c => this.files.push(files.filter(f => f.category === c)));
      this.filteredCategories = of(this.categories);
    });

    this.fileForm = this.formBuilder.group({
      name: ['', Validators.required],
      category: ['', Validators.required]
    });

    this.filteredCategories = this.fileForm.get('category').valueChanges
      .pipe(
        startWith(''),
        map(category => category ? this.filter(category) : this.categories.slice())
      );

    this.store.dispatch(new DownloadFilesLoad());
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.categories.filter(category => category.toLowerCase().indexOf(filterValue) > 0);
  }

  resetForm() {
    this.fileForm = this.formBuilder.group({
      name: ['', Validators.required],
      category: ['', Validators.required]
    });

    this.filteredCategories = this.fileForm.get('category').valueChanges
      .pipe(
        startWith(''),
        map(category => category ? this.filter(category) : this.categories.slice())
      );

    this.fileToUpload = null;
    this.uploadControl.setValue('');
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  delete(f) {
    const dialogRef = this.dialog.open(DeleteConfirmComponent, {
      data: {
        name: f.name
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DownloadFileDelete(f.id));
      }
    });
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.fileToUpload);
    formData.append('name', this.fileForm.get('name').value);
    formData.append('category', this.fileForm.get('category').value);

    this.store.dispatch(new DownloadFileCreateWithForm(formData));
    this.resetForm();
  }
}
