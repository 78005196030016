import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SnackbarNotification} from '../models/ui/snackbar-notification.model';

@Injectable({
  providedIn: 'root'
})
export class UIService {
  private snackBarSubject = new Subject<SnackbarNotification>();

  constructor(private http: HttpClient) {
  }


  dispatchNotification(notification: SnackbarNotification) {
    this.snackBarSubject.next(notification);
  }

  getSnackbarNotifications(): Observable<SnackbarNotification> {
    return this.snackBarSubject.asObservable();
  }
}
