import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BuildingType} from '../../../../models/BuildingType';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import {getBuildingTypes} from '../../../../selectors/building-type.selector';
import {MatTableDataSource} from '@angular/material';
import {BuildingTypesLoad} from '../../../../actions/building-type.actions';
import {Project} from '../../../../models/Project';
import {areProjectsLoading, getProjects} from '../../../../selectors/project.selector';
import {ProjectCreate, ProjectsLoad} from '../../../../actions/project.actions';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'customer-detail-projects',
  templateUrl: './customer-detail-projects.component.html',
  styleUrls: ['./customer-detail-projects.component.scss']
})
export class CustomerDetailProjectsComponent implements OnInit {

  projects: Project[] = [];
  dataSource = null;
  columnsToDisplay = ['name'];

  isLoading$;

  buildingTypes: BuildingType[] = [];
  projectForm: FormGroup;

  showCreateProject = false;

  @Input() customerId: string;

  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.store.pipe(select(getProjects)).subscribe(projects => {
      this.projects = projects.filter(p => p.customer === this.customerId);
      this.dataSource = new MatTableDataSource<Project>(this.projects);
    });

    this.isLoading$ = this.store.pipe(select(areProjectsLoading));

    this.store.pipe(select(getBuildingTypes)).subscribe(buildingTypes => {
      this.buildingTypes = buildingTypes;

      if (this.projectForm) {
        this.projectForm.get('buildingType').setValue(this.buildingTypes[0].id);
      }
    });

    this.store.dispatch(new ProjectsLoad());
    this.store.dispatch(new BuildingTypesLoad());

    this.projectForm = this.formBuilder.group({
      name: ['', Validators.required],
      street: [''],
      zipCode: [''],
      city: [''],
      country: [''],
      altitude: [0],
      buildingType: []
    });
  }

  createProject() {
    const p: Project = new Project();

    p.customer = this.customerId;
    p.buildingType = this.projectForm.get('buildingType').value;
    p.name = this.projectForm.get('name').value;
    p.street = this.projectForm.get('street').value;
    p.zipCode = this.projectForm.get('zipCode').value;
    p.city = this.projectForm.get('city').value;
    p.country = this.projectForm.get('country').value;
    p.altitude = this.projectForm.get('altitude').value;

    this.store.dispatch(new ProjectCreate(p));
    this.showCreateProject = false;
  }
}
