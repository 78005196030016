import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Injectable} from '@angular/core';
import {PartnerActionTypes} from '../actions/partner.actions';
import {PartnerService} from '../services/partner.service';
import {ProductActionTypes} from '../actions/product.actions';

@Injectable()
export class PartnerEffects {

  @Effect()
  loadPartner$ = this.actions$
    .pipe(
      ofType(PartnerActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.partnerService.get((action as any).payload)
        .pipe(
          map(partner => ({type: PartnerActionTypes.SINGLE_LOADED, payload: partner})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  loadPartners$ = this.actions$
    .pipe(
      ofType(PartnerActionTypes.ALL_LOAD),
      mergeMap(() => this.partnerService.all()
        .pipe(
          map(partners => ({type: PartnerActionTypes.ALL_LOADED, payload: partners})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updatePartner$ = this.actions$
    .pipe(
      ofType(PartnerActionTypes.UPDATE_SEND),
      mergeMap((action) => this.partnerService.update((action as any).payload)
        .pipe(
          map(partner => ({type: PartnerActionTypes.UPDATE_DONE, payload: partner})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updatePartnerForm$ = this.actions$
    .pipe(
      ofType(PartnerActionTypes.UPDATE_SEND_FORM),
      mergeMap((action) => this.partnerService.updateForm((action as any).payload)
        .pipe(
          map(partner => ({type: PartnerActionTypes.UPDATE_DONE, payload: partner})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createPartner$ = this.actions$
    .pipe(
      ofType(PartnerActionTypes.CREATE_SEND),
      mergeMap((action) => this.partnerService.create((action as any).payload)
        .pipe(
          map(partner => ({type: PartnerActionTypes.CREATE_DONE, payload: partner})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private partnerService: PartnerService
  ) {
  }
}
