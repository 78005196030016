import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {OfferActionTypes} from '../actions/offer.actions';
import {OfferService} from '../services/offer.service';

@Injectable()
export class OfferEffects {


  @Effect()
  loadOffersForProject$ = this.actions$
    .pipe(
      ofType(OfferActionTypes.ALL_FOR_PROJECT_LOAD),
      mergeMap((action) => this.offerService.allForProject((action as any).payload)
        .pipe(
          map(offers => ({type: OfferActionTypes.ALL_FOR_PROJECT_LOADED, payload: offers})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  loadOffersForPartner$ = this.actions$
    .pipe(
      ofType(OfferActionTypes.ALL_FOR_PARTNER_LOAD),
      mergeMap((action) => this.offerService.allForPartner((action as any).payload)
        .pipe(
          map(offers => ({type: OfferActionTypes.ALL_FOR_PARTNER_LOADED, payload: offers})),
          catchError(() => EMPTY)
        )
      )
    );


    @Effect()
    loadOffersForCustomer$ = this.actions$
        .pipe(
            ofType(OfferActionTypes.ALL_FOR_CUSTOMER_LOAD),
            mergeMap((action) => this.offerService.allForCustomer((action as any).payload)
                .pipe(
                    map(offers => ({type: OfferActionTypes.ALL_FOR_CUSTOMER_LOADED, payload: offers})),
                    catchError(() => EMPTY)
                )
            )
        );


    constructor(
    private actions$: Actions,
    private offerService: OfferService
  ) {
  }
}
