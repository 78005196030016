import {Component, OnInit, ViewChild} from '@angular/core';
import {Customer} from '../../../models/Customer';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {getCustomerStateData, getCustomerStateIsLoading} from '../../../selectors/customer.selector';
import {getOwnUser} from '../../../selectors/user.selector';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {CustomerPartnerAssign, CustomersLoad, CustomersUnassignedLoad} from '../../../actions/customer.actions';
import {User} from '../../../models/user.model';
import {SetTitle} from '../../../actions/ui.actions';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Partner} from '../../../models/Partner';
import {getPartnerStateData} from '../../../selectors/partner.selector';
import {PartnersLoad} from '../../../actions/partner.actions';

@Component({
  selector: 'unassigned-customers',
  templateUrl: './unassigned-customers.component.html',
  styleUrls: ['./unassigned-customers.component.scss']
})
export class UnassignedCustomersComponent implements OnInit {

  customers: Customer[] = [];
  dataSource = null;
  isLoading$;
  resultsLength = 0;
  columnsToDisplay = ['name'];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  partners: Partner[] = [];

  user: User;

  myControl = new FormControl();
  filteredOptions: Observable<Partner[]>;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;

      this.store.pipe(select(getPartnerStateData)).subscribe(partners => {
        this.partners = partners;

        this.store.pipe(select(getCustomerStateData)).subscribe(customers => {
          this.customers = customers.filter(c => !c.hasBeenAssigned);
          this.resultsLength = customers.length;
          this.dataSource = new MatTableDataSource<{ customer: Customer, form: FormGroup }>(this.customers.map(c => ({
            customer: c,
            form: this.formBuilder.group({
              partner: [this.partners.length > 0 ? this.partners[0] : undefined, Validators.required]
            })
          })));
          // TODO: https://blog.angular-university.io/angular-material-data-table/
          setTimeout(() => this.dataSource.paginator = this.paginator, 300);
        });
      });
    });

    this.store.dispatch(new PartnersLoad());

    this.isLoading$ = this.store.pipe(select(getCustomerStateIsLoading));
    this.store.dispatch(new CustomersUnassignedLoad());

    this.store.dispatch(new SetTitle('Unzugeordnete Kunden'));

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith<string | Partner>(''),
        map(value => typeof value === 'string' ? value : value.companyName),
        map(name => name ? this._filter(name) : this.partners.slice())
      );
  }

  assignPartner(row) {
    this.store.dispatch(new CustomerPartnerAssign({customer: row.customer, partner: row.form.get('partner').value}));
  }

  displayFn(partner?: Partner): string | undefined {
    return partner ? partner.companyName : undefined;
  }

  private _filter(name: string): Partner[] {
    const filterValue = name.toLowerCase();

    return this.partners.filter(option => option.companyName.toLowerCase().indexOf(filterValue) === 0);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
