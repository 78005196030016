import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ProjectRoomType} from '../models/project-room-type.model';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectRoomTypeService extends BaseService<ProjectRoomType> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_PROJECTROOMTYPE);
  }
}
