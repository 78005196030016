import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialog, MatPaginator, MatTableDataSource} from '@angular/material';
import {User} from '../../../../../models/user.model';
import {Observable, pipe} from 'rxjs';
import {Project} from '../../../../../models/Project';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {OffersForProjectLoad} from '../../../../../actions/offer.actions';
import {areOffersLoading, getOffers} from '../../../../../selectors/offer.selector';
import {Offer} from '../../../../../models/offer';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {OfferOverlayComponent} from '../offer-overlay/offer-overlay.component';
import {OfferConfirmComponent} from '../offer-confirm/offer-confirm.component';
import {getOwnUser} from '../../../../../selectors/user.selector';
import {EmailOfferComponent} from '../email-offer/email-offer.component';

@Component({
  selector: 'project-offers',
  templateUrl: './project-offers.component.html',
  styleUrls: ['./project-offers.component.scss']
})
export class ProjectOffersComponent implements OnInit {

  offers: Offer[];
  dataSource = null;
  columnsToDisplay = ['number'];
  isLoading$;
  resultsLength = 0;
  showCreateProduct = false;
  productForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: User;
  filteredOptions: Observable<any[]>;

  @Input() project: Project;

  constructor(private http: HttpClient, private store: Store<fromRoot.State>, private formBuilder: FormBuilder, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;
    });

    this.store.select(pipe(getOffers)).subscribe(offers => {
      this.offers = offers;
      this.dataSource = new MatTableDataSource<Offer>(this.offers);
      this.resultsLength = this.offers.length;
      setTimeout(() => this.dataSource.paginator = this.paginator, 300);
    });

    this.store.dispatch(new OffersForProjectLoad(this.project.id));

    this.isLoading$ = this.store.select(pipe(areOffersLoading));
  }

  mailOffer(offer: Offer) {
    const dialogRef = this.dialog.open(EmailOfferComponent, {
      data: {
        recipients: [
          this.project.customer
        ],
        sender: this.user.email,
        offer: offer.number
      },
      panelClass: 'simplyheat-modalbox'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  acceptOffer(offer: Offer) {
    const dialogRef = this.dialog.open(OfferConfirmComponent, {
      data: {
        offerNumber: offer.number
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.http.post(environment.baseUrl + '/api/offers/accept',
          {
            id: offer.id
          }).subscribe(response => {
          this.store.dispatch(new OffersForProjectLoad(this.project.id));
        });
      }
    });
  }
}
