import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {MatTableDataSource} from '@angular/material';
import {InframeshMountingStyle} from '../../../models/inframesh-mounting-style.model';
import {areInframeshMountingStylesLoading, getInframeshMountingStyles} from '../../../selectors/inframesh-mounting-style.selector';
import {InframeshMountingStyleCreate, InframeshMountingStylesLoad} from '../../../actions/inframesh-mounting-style.actions';

@Component({
  selector: 'inframesh-mounting-style',
  templateUrl: './inframesh-mounting-style.component.html',
  styleUrls: ['./inframesh-mounting-style.component.scss']
})
export class InframeshMountingStyleComponent implements OnInit {

  inframeshMountingStyles: InframeshMountingStyle[] = [];
  dataSource = null;
  columnsToDisplay = ['name'];

  inframeshMountingStyleForm: FormGroup;
  showCreateInframeshMountingStyle = false;

  isLoading$;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.store.pipe(select(getInframeshMountingStyles)).subscribe(inframeshMountingStyles => {
      this.inframeshMountingStyles = inframeshMountingStyles;
      this.dataSource = new MatTableDataSource<InframeshMountingStyle>(inframeshMountingStyles);
    });

    this.isLoading$ = this.store.pipe(select(areInframeshMountingStylesLoading));
    this.store.dispatch(new InframeshMountingStylesLoad());

    this.inframeshMountingStyleForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  createInframeshMountingStyle() {

    const inframeshMountingStyle = new InframeshMountingStyle();
    inframeshMountingStyle.name = this.inframeshMountingStyleForm.get('name').value;

    this.store.dispatch(new InframeshMountingStyleCreate(inframeshMountingStyle));
    this.showCreateInframeshMountingStyle = false;
  }
}
