import {Action} from '@ngrx/store';
import {Product} from '../models/product.model';

export enum ProductActionTypes {
  ALL_LOAD = '[Product] All Loading...',
  ALL_LOADED = '[Product] All Loaded.',
  SINGLE_LOAD = '[Product] Single Loading...',
  SINGLE_LOADED = '[Product] Single Loaded.',
  CREATE_SEND = '[Product] creating...',
  CREATE_DONE = '[Product] created.',
  UPDATE_SEND = '[Product] updating...',
  UPDATE_DONE = '[Product] updated.'
}

export class ProductsLoad implements Action {
  readonly type = ProductActionTypes.ALL_LOAD;
}

export class ProductsLoaded implements Action {
  readonly type = ProductActionTypes.ALL_LOADED;

  constructor(public payload: Product[]) {
  }
}

export class ProductCreate implements Action {
  readonly type = ProductActionTypes.CREATE_SEND;

  constructor(public payload: Product) {
  }
}

export class ProductCreated implements Action {
  readonly type = ProductActionTypes.CREATE_DONE;

  constructor(public payload: Product) {
  }
}

export class ProductLoad implements Action {
  readonly type = ProductActionTypes.SINGLE_LOAD;

  constructor(public payload: string) {
  }
}

export class ProductLoaded implements Action {
  readonly type = ProductActionTypes.SINGLE_LOADED;

  constructor(public payload: Product) {
  }
}

export class ProductUpdate implements Action {
  readonly type = ProductActionTypes.UPDATE_SEND;

  constructor(public payload: Product) {
  }
}


export class ProductUpdated implements Action {
  readonly type = ProductActionTypes.UPDATE_DONE;

  constructor(public payload: Product) {
  }
}


export type ProductActions =
  ProductsLoad
  | ProductsLoaded
  | ProductCreate
  | ProductCreated
  | ProductLoad
  | ProductLoaded
  | ProductUpdate
  | ProductUpdated;
