export class Customer {
  id: string;
  partner: string;
  firstname: string;
  surname: string;
  companyName: string;
  uid: string;
  registerNumber: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
  hasBeenAssigned: boolean;
  isNew: boolean;
  user: string;
  kdrabatt: string;
  person1_name: string;
  person1_email: string;
  person1_phone: string;
  person2_name: string;
  person2_email: string;
  person2_phone: string;
  person3_name: string;
  person3_email: string;
  person3_phone: string;
  nachruf: string;
  notiz: string;
  accepted: string;

  constructor();

  constructor(
    id?: string,
    partner?: string,
    firstname?: string,
    surname?: string,
    companyName?: string,
    uid?: string,
    registerNumber?: string,
    street?: string,
    zipCode?: string,
    city?: string,
    country?: string,
    phone?: string,
    email?: string,
    hasBeenAssigned?:boolean,
    isNew?: boolean,
    user?:string,
    kdrabatt?:string,
    person1_name?:string,
    person1_email?:string,
    person1_phone?:string,
    person2_name?:string,
    person2_email?:string,
    person2_phone?:string,
    person3_name?:string,
    person3_email?:string,
    person3_phone?:string,
    nachruf?:string,
    notiz?:string,
    accepted?:string,
  )
  {
    this.id = id;
    this.firstname = firstname;
    this.surname = surname;
    this.companyName = companyName;
    this.uid = uid;
    this.registerNumber = registerNumber;
    this.street = street;
    this.zipCode = zipCode;
    this.city = city;
    this.phone = phone;
    this.email = email;
    this.user = user;
    this.kdrabatt = kdrabatt;
    this.hasBeenAssigned = hasBeenAssigned;
    this.isNew = isNew;
    this.person1_name = person1_name;
    this.person1_email = person1_email;
    this.person1_phone = person1_phone;
    this.person2_name = person2_name;
    this.person2_email = person2_email;
    this.person2_phone = person2_phone;
    this.person3_name = person3_name;
    this.person3_email = person3_email;
    this.person3_phone = person3_phone;
    this.nachruf = nachruf;
    this.notiz = notiz;
    this.accepted = accepted;
  }
}
