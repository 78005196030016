import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {OffersForProjectLoad} from '../../../../../actions/offer.actions';

@Component({
  selector: 'app-offer-overlay',
  templateUrl: './offer-overlay.component.html',
  styleUrls: ['./offer-overlay.component.scss']
})
export class OfferOverlayComponent implements OnInit {

  offerTypes: Array<{ id: string, name: string }> = new Array();
  offerFormStep1: FormGroup;
  offerFormStep2: FormGroup;

  thermostatTypes = [];
  controllerTypes = [];
  panelTypes = [];
  floorTypes = [];

  heizlastTypes = [];
  kabelTypes = [];

  constructor(private http: HttpClient,
              private formBuilder: FormBuilder,
              public dialogRef: MatDialogRef<OfferOverlayComponent>,
              @Inject(MAT_DIALOG_DATA) public data) {
  }

  ngOnInit() {
    //this.offerTypes.push({id: '1', name: 'Alle Varianten'});
    this.offerTypes.push({id: '2', name: 'Heizlast'});
    this.offerTypes.push({id: '3', name: 'Inframesh Basic'});
    this.offerTypes.push({id: '4', name: 'Inframesh Medium'});
    this.offerTypes.push({id: '5', name: 'Inframesh Premium'});
    this.offerTypes.push({id: '7', name: 'Heizkörper'});
    this.offerTypes.push({id: '9', name: 'Infrafloor'});
    this.offerTypes.push({id: '8', name: 'Sortiment'});


    this.thermostatTypes.push({id: '1', name: 'Digital UP'});
    this.thermostatTypes.push({id: '2', name: 'Digital UP mit Touch '});
    this.thermostatTypes.push({id: '3', name: 'Digital UP mit WLAN'});
    this.thermostatTypes.push({id: '4', name: 'Funkthermostat Air-2'});


      this.controllerTypes.push({id: '1', name: 'Normal'});
      this.controllerTypes.push({id: '2', name: 'Zonenregelung'});
      this.controllerTypes.push({id: '3', name: 'Einzelraumregelung'});
      this.controllerTypes.push({id: '4', name: 'ohne Regelung'});

      this.panelTypes.push({id: '1', name: 'Infrarotplatten'});
      this.panelTypes.push({id: '2', name: 'Infrarotkonvektoren'});

    this.floorTypes.push({id: '1', name: 'Parkettheizung'});
    this.floorTypes.push({id: '2', name: 'Fliesenheizung'});
    this.floorTypes.push({id: '3', name: 'Estrichspeicherheizung'});


    this.heizlastTypes.push({id: '0', name: 'ohne Heizlastberechnung'});
    this.heizlastTypes.push({id: '1', name: 'mit Heizlastberechnung'});

    this.kabelTypes.push({id: '0', name: 'ohne Heizlastberechnung'});
    this.kabelTypes.push({id: '1', name: 'mit Heizlastberechnung'});




    this.offerFormStep1 = this.formBuilder.group({
      offerType: [this.offerTypes[0].id],
      thermostatType: [this.thermostatTypes[0].id],
        controllerType: [this.controllerTypes[2].id],
        panelType: [this.panelTypes[0].id],
      floorType: [this.floorTypes[0].id],
      heizlastType: [this.heizlastTypes[0].id],
      kabelType: [this.kabelTypes[0].id]
    });



    this.offerFormStep2 = this.formBuilder.group({
      offerIntro: [this.data.offerIntro],
      offerTerms: [this.data.offerTerms],
      consolidateInfra: [true]
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  download() {
    const products = [];
    if (this.offerFormStep1.get('offerType').value !== '1') {
      this.http.post(environment.baseUrl + '/api/offerPDF',
        {
          project: this.data.project,
          offerType: this.offerFormStep1.get('offerType').value,
          thermostatType: this.offerFormStep1.get('thermostatType').value,
            controllerType: this.offerFormStep1.get('controllerType').value,
          intro: this.offerFormStep2.get('offerIntro').value,
          terms: this.offerFormStep2.get('offerTerms').value,
          consolidateInfra: this.offerFormStep2.get('consolidateInfra').value,
            panelType: this.offerFormStep1.get('panelType').value,
          floorType: this.offerFormStep1.get('floorType').value,
          kabelType: this.offerFormStep1.get('kabelType').value,
          heizlastType: this.offerFormStep1.get('heizlastType').value,
        }).subscribe(response => {
        this.dialogRef.close();
      });
    } else {
      [2, 3, 4, 5, 7, 8,9].forEach(n => {
        this.http.post(environment.baseUrl + '/api/offerPDF',
          {
            project: this.data.project,
            offerType: n,
            thermostatType: this.offerFormStep1.get('thermostatType').value,
              controllerType: this.offerFormStep1.get('controllerType').value,
            intro: this.offerFormStep2.get('offerIntro').value,
            terms: this.offerFormStep2.get('offerTerms').value,
            consolidateInfra: this.offerFormStep2.get('consolidateInfra').value,
              panelType: this.offerFormStep1.get('panelType').value,
            floorType: this.offerFormStep1.get('floorType').value,

            heizlastType: this.offerFormStep1.get('heizlastType').value,
          }).subscribe(response => {
        });
      });
      this.dialogRef.close();

    }
  }
}
