import {Component, OnInit, ViewChild} from '@angular/core';
import {Customer} from '../../models/Customer';
import {Observable, pipe} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import {User} from '../../models/user.model';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../reducers';
import {getCustomerStateData, getCustomerStateIsLoading} from '../../selectors/customer.selector';
import {areUsersLoading, getOwnUser, getUsers} from '../../selectors/user.selector';
import {CustomerCreate, CustomersLoad} from '../../actions/customer.actions';
import {SetTitle} from '../../actions/ui.actions';
import {UserCreate, UsersLoad, UserUpdate} from '../../actions/user.actions';
import {getPartnerStateData} from '../../selectors/partner.selector';
import {Partner} from '../../models/Partner';
import {PartnersLoad} from '../../actions/partner.actions';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  partners: Partner[] = [];
  users: User[] = [];
  dataSource = null;
  columnsToDisplay = ['firstname'];

  isLoading$;
  resultsLength = 0;
  showCreateUser = false;
  userForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  user: User;

  roles = [];

  filteredOptions: Observable<Partner[]>;

  selectedUser: User;

  constructor(private store: Store<fromRoot.State>, private formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.store.pipe(select(getOwnUser)).subscribe(user => {
      this.user = user;
      this.store.select(pipe(getUsers)).subscribe(users => {
        this.users = users.filter(u => u.id !== user.id);
        this.resultsLength = users.length;
        this.dataSource = new MatTableDataSource<User>(this.users);
        // TODO: https://blog.angular-university.io/angular-material-data-table/
        setTimeout(() => this.dataSource.paginator = this.paginator, 300);
      });
    });

    this.roles.push({id: 'partner', name: 'Partner'});
    this.roles.push({id: 'admin', name: 'Administrator'});
    this.roles.push({id: 'user', name: 'User'});

    this.store.select(pipe(getPartnerStateData)).subscribe(partners => {
      this.partners = partners;

      if (partners && partners.length > 0) {
        this.userForm = this.formBuilder.group({
          firstname: ['', Validators.required],
          surname: ['', Validators.required],
          role: [this.roles[0]],
          partner: [this.partners[0]],
          email: ['', Validators.required],
          phone: [''],
          password: [''],
          passwordrepeat: ['']
        });

        this.filteredOptions = this.userForm.get('partner').valueChanges
          .pipe(
            startWith<string | Partner>(''),
            map(value => typeof value === 'string' ? value : value.companyName),
            map(name => name ? this._filter(name) : this.partners.slice())
          );
      }
    });

    this.userForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      role: [this.roles[0]],
      partner: [],
      email: ['', Validators.required],
      phone: [''],
      password: [''],
      passwordrepeat: ['']
    });

    this.store.dispatch(new PartnersLoad());

    this.isLoading$ = this.store.pipe(select(areUsersLoading));
    this.store.dispatch(new UsersLoad());


    this.store.dispatch(new SetTitle('Benutzerübersicht'));

    this.filteredOptions = this.userForm.get('partner').valueChanges
      .pipe(
        startWith<string | Partner>(''),
        map(value => typeof value === 'string' ? value : value.companyName),
        map(name => name ? this._filter(name) : this.partners.slice())
      );
  }

  getPartnerCompanyName(id: string) {
    return this.partners.find(p => p.id === id).companyName;
  }

  createUser() {
    if (!this.selectedUser) {
      const u: User = new User();

      u.firstname = this.userForm.get('firstname').value;
      u.surname = this.userForm.get('surname').value;
      u.email = this.userForm.get('email').value;
      u.partner = this.userForm.get('partner').value.id;
      u.role = this.userForm.get('role').value.id;
      u.password = this.userForm.get('password').value;
      u.phone = this.userForm.get('phone').value;

      this.store.dispatch(new UserCreate(u));

    } else {
      const u = this.selectedUser;

      u.firstname = this.userForm.get('firstname').value;
      u.surname = this.userForm.get('surname').value;
      u.email = this.userForm.get('email').value;
      u.partner = this.userForm.get('partner').value.id;
      u.role = this.userForm.get('role').value.id;
      u.password = this.userForm.get('password').value;
      u.phone = this.userForm.get('phone').value;

      this.store.dispatch(new UserUpdate(u));
    }

    this.selectedUser = undefined;
    this.showCreateUser = false;
    this.userForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      role: [this.roles[0]],
      partner: [this.partners[0]],
      email: ['', Validators.required],
      phone: [''],
      password: [''],
      passwordrepeat: ['']
    });
  }

  updateUser(user) {
    this.selectedUser = user;

    this.showCreateUser = true;
    this.userForm = this.formBuilder.group({
      firstname: [user.firstname, Validators.required],
      surname: [user.surname, Validators.required],
      role: [this.roles.find(r => r.id === user.role)],
      partner: [this.partners.find(p => p.id === user.partner)],
      email: [user.email, Validators.required],
      phone: [user.phone],
      password: [''],
      passwordrepeat: ['']
    });
  }

  closeEdit() {
    this.selectedUser = undefined;
    this.showCreateUser = false;
    this.userForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      surname: ['', Validators.required],
      role: [this.roles[0]],
      partner: [this.partners[0]],
      email: ['', Validators.required],
      phone: [''],
      password: [''],
      passwordrepeat: ['']
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  displayFn(partner?: Partner): string | undefined {
    return partner ? partner.companyName : undefined;
  }

  private _filter(name: string): Partner[] {
    const filterValue = name.toLowerCase();

    return this.partners.filter(option => option.companyName.toLowerCase().indexOf(filterValue) > 0);
  }
}
