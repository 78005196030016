import {Action} from '@ngrx/store';
import {User} from '../models/user.model';
import {SnackbarNotification} from '../models/ui/snackbar-notification.model';

export enum UserActionTypes {
  DO_LOGIN = '[User] logging in...',
  DO_LOGIN_ERROR = '[User] error logging in.',
  LOGGED_IN = '[User] logged in.',
  LOGOUT = '[User] logout',
  SINGLE_LOAD = '[User] loading...',
  SINGLE_LOADED = '[User] loaded.',
  OWN_LOAD = '[User] own loading...',
  OWN_LOADED = '[User] own loaded.',
  ALL_LOAD = '[User] all loading...',
  ALL_LOADED = '[User] all loaded.',
  CREATE_SEND = '[User] creating...',
  CREATE_DONE = '[User] created',
  UPDATE_SEND = '[User] updating...',
  UPDATE_DONE = '[User] updated.'
}

export class UserCreate implements Action {
  readonly type = UserActionTypes.CREATE_SEND;

  constructor(public payload: User) {
  }
}

export class UserCreated implements Action {
  readonly type = UserActionTypes.CREATE_DONE;

  constructor(public payload: User) {
  }
}

export class UserUpdate implements Action {
  readonly type = UserActionTypes.UPDATE_SEND;

  constructor(public payload: User) {
  }
}

export class UserUpdated implements Action {
  readonly type = UserActionTypes.UPDATE_DONE;

  constructor(public payload: User) {
  }
}

export class Login implements Action {
  readonly type = UserActionTypes.DO_LOGIN;

  constructor(public payload: { username: string, password: string }) {
  }
}

export class LoginError implements Action {
  readonly type = UserActionTypes.DO_LOGIN_ERROR;

  constructor(public payload: string) {
  }
}

export class LoggedIn implements Action {
  readonly type = UserActionTypes.LOGGED_IN;

  constructor(public payload: { accessToken: string, refreshToken: string }) {
  }
}

export class UserLoad implements Action {
  readonly type = UserActionTypes.SINGLE_LOAD;

  constructor(public payload: string) {
  }
}

export class UserLoaded implements Action {
  readonly type = UserActionTypes.SINGLE_LOADED;

  constructor(public payload: User) {
  }
}

export class UsersLoad implements Action {
  readonly type = UserActionTypes.ALL_LOAD;
}

export class UsersLoaded implements Action {
  readonly type = UserActionTypes.ALL_LOADED;

  constructor(public payload: User[]) {
  }
}

export class UserOwnLoad implements Action {
  readonly type = UserActionTypes.OWN_LOAD;
}

export class UserOwnLoaded implements Action {
  readonly type = UserActionTypes.OWN_LOADED;

  constructor(public payload: User) {
  }
}

export class Logout implements Action {
  readonly type = UserActionTypes.LOGOUT;

  constructor(public payload: SnackbarNotification) {
  }
}

export type UserActions =
  Login
  | LoginError
  | LoggedIn
  | Logout
  | UserLoad
  | UserLoaded
  | UserOwnLoad
  | UserOwnLoaded
  | UsersLoad
  | UsersLoaded
  | UserCreate
  | UserCreated
  | UserUpdate
  | UserUpdated
  ;
