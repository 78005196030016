import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {Product} from '../models/product.model';
import {ProductService} from '../services/product.service';
import {ProductActionTypes} from '../actions/product.actions';

@Injectable()
export class ProductEffects {

  @Effect()
  loadProducts$ = this.actions$
    .pipe(
      ofType(ProductActionTypes.ALL_LOAD),
      mergeMap(() => this.productService.all()
        .pipe(
          map(products => ({type: ProductActionTypes.ALL_LOADED, payload: products})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  createProducts$ = this.actions$
    .pipe(
      ofType(ProductActionTypes.CREATE_SEND),
      mergeMap((action) => this.productService.create((action as any).payload)
        .pipe(
          map(product => ({type: ProductActionTypes.CREATE_DONE, payload: product})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  updateProducts$ = this.actions$
    .pipe(
      ofType(ProductActionTypes.UPDATE_SEND),
      mergeMap((action) => this.productService.update((action as any).payload)
        .pipe(
          map(product => ({type: ProductActionTypes.UPDATE_DONE, payload: product})),
          catchError(() => EMPTY)
        )
      )
    );

  @Effect()
  loadProduct$ = this.actions$
    .pipe(
      ofType(ProductActionTypes.SINGLE_LOAD),
      mergeMap((action) => this.productService.get((action as any).payload)
        .pipe(
          map(product => ({type: ProductActionTypes.SINGLE_LOADED, payload: product})),
          catchError(() => EMPTY)
        )
      )
    );

  constructor(
    private actions$: Actions,
    private productService: ProductService
  ) {
  }
}
