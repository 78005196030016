import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-entity-table',
  templateUrl: './entity-table.component.html',
  styleUrls: ['./entity-table.component.scss']
})
export class EntityTableComponent implements OnInit {

  title: string;
  entity;
  service;
  editLink;
  showCreateEntity = false;
  isLoading$;
  dataSource;
  entityForm;
  columnsToDisplay = ['name'];

  @Input() icon: String;
  @Input() inlineEdit = false;

  constructor() { }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    //this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createEntity() {
    console.log('create entity');
  }

}
