import {ProjectRoom} from '../models/project-room.model';
import {ProjectRoomActions, ProjectRoomActionTypes} from '../actions/project-room.actions';
import {ProjectActionTypes} from '../actions/project.actions';


export interface ProjectRoomState {
  isLoading: Boolean;
  projectRooms: ProjectRoom[];
}

export const inititalState: ProjectRoomState = {
  isLoading: false,
  projectRooms: []
};

export function reducer(
  state = inititalState,
  action: ProjectRoomActions
): ProjectRoomState {
  switch (action.type) {
    case ProjectRoomActionTypes.ALL_LOAD: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomActionTypes.ALL_LOADED: {
      return {
        ...state,
        isLoading: false,
        projectRooms: action.payload
      };
    }

    case ProjectRoomActionTypes.CREATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomActionTypes.CREATE_DONE: {
      const projectRooms = Object.assign([], state.projectRooms);

      projectRooms.push(action.payload);

      return {
        ...state,
        isLoading: false,
        projectRooms: projectRooms
      };
    }

    case ProjectRoomActionTypes.UPDATE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomActionTypes.UPDATE_DONE: {
      let projectRooms = Object.assign([], state.projectRooms);

      if (projectRooms.length > 0) {
        const updatedProjectRooms = projectRooms.filter(p => p.id === action.payload.id);

        if (updatedProjectRooms) {
          updatedProjectRooms.forEach(p => projectRooms.splice(projectRooms.indexOf(p), 1));
        }
      } else {
        projectRooms = [];
      }

      projectRooms.push(action.payload);

      return {
        ...state,
        isLoading: false,
        projectRooms: projectRooms
      };
    }

    case ProjectRoomActionTypes.DELETE_SEND: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ProjectRoomActionTypes.DELETE_DONE: {
      let projectRooms = Object.assign([], state.projectRooms);

      if (projectRooms.length > 0) {
        projectRooms.splice(projectRooms.indexOf(projectRooms.find(p => p.id === action.payload)), 1);
      } else {
        projectRooms = [];
      }

      return {
        ...state,
        isLoading: true,
        projectRooms: projectRooms
      };
    }

    default: {
      return state;
    }
  }
}
