import {Action} from '@ngrx/store';
import {InframeshMountingStyle} from '../models/inframesh-mounting-style.model';

export enum InframeshMountingStyleActionTypes {
  ALL_LOAD = '[InframeshMountingTypes] All Loading...',
  ALL_LOADED = '[InframeshMountingTypes] All Loaded.',
  SINGLE_LOAD = '[InframeshMountingTypes] Single Loading...',
  SINGLE_LOADED = '[InframeshMountingTypes] Single Loaded.',
  CREATE_SEND = '[InframeshMountingTypes] creating...',
  CREATE_DONE = '[InframeshMountingTypes] created.',
  UPDATE_SEND = '[InframeshMountingTypes] updating...',
  UPDATE_DONE = '[InframeshMountingTypes] updated.'
}

export class InframeshMountingStylesLoad implements Action {
  readonly type = InframeshMountingStyleActionTypes.ALL_LOAD;
}

export class InframeshMountingStylesLoaded implements Action {
  readonly type = InframeshMountingStyleActionTypes.ALL_LOADED;
  constructor(public payload: InframeshMountingStyle[]) {}
}

export class InframeshMountingStyleLoad implements Action {
  readonly type = InframeshMountingStyleActionTypes.SINGLE_LOAD;
  constructor(public payload: string) {}
}

export class InframeshMountingStyleLoaded implements Action {
  readonly type = InframeshMountingStyleActionTypes.SINGLE_LOADED;
  constructor(public payload: InframeshMountingStyle) {}
}

export class InframeshMountingStyleCreate implements Action {
  readonly type = InframeshMountingStyleActionTypes.CREATE_SEND;
  constructor(public payload: InframeshMountingStyle) {}
}

export class InframeshMountingStyleCreated implements Action {
  readonly type = InframeshMountingStyleActionTypes.CREATE_DONE;
  constructor(public payload: InframeshMountingStyle) {}
}

export class InframeshMountingStyleUpdate implements Action {
  readonly type = InframeshMountingStyleActionTypes.UPDATE_SEND;
  constructor(public payload: InframeshMountingStyle) {}
}

export class InframeshMountingStyleUpdated implements Action {
  readonly type = InframeshMountingStyleActionTypes.UPDATE_DONE;
  constructor(public payload: InframeshMountingStyle) {}
}

export type InframeshMountingStyleActions = InframeshMountingStylesLoad | InframeshMountingStylesLoaded |
                                            InframeshMountingStyleLoad | InframeshMountingStyleLoaded |
                                            InframeshMountingStyleCreate | InframeshMountingStyleCreated |
                                            InframeshMountingStyleUpdate | InframeshMountingStyleUpdated;
