import {CustomerState} from '../reducers/customer.reducer';
import {createFeatureSelector, createSelector, select} from '@ngrx/store';

export const selectCustomerState = createFeatureSelector('customer');

export const selectCustomers = (state: CustomerState) => state.customers;
export const selectCustomerIsLoading = (state: CustomerState) => state.isLoading;

export const getCustomerStateData = createSelector(selectCustomerState, selectCustomers);
export const getCustomerStateIsLoading = createSelector(selectCustomerState, selectCustomerIsLoading);
