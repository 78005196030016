import {Action} from '@ngrx/store';
import {BuildingType} from '../models/BuildingType';

export enum BuildingTypeActionTypes {
  ALL_LOAD = '[BuildingTypes] All Loading...',
  ALL_LOADED = '[BuildingTypes] All Loaded.',
  SINGLE_LOAD = '[BuildingTypes] Single Loading...',
  SINGLE_LOADED = '[BuildingTypes] Single Loaded.',
  CREATE_SEND = '[BuildingTypes] creating...',
  CREATE_DONE = '[BuildingTypes] created.',
  UPDATE_SEND = '[BuildingTypes] updating...',
  UPDATE_DONE = '[BuildingTypes] updated.'
}

export class BuildingTypesLoad implements Action {
  readonly type = BuildingTypeActionTypes.ALL_LOAD;
}

export class BuildingTypesLoaded implements Action {
  readonly type = BuildingTypeActionTypes.ALL_LOADED;
  constructor(public payload: BuildingType[]) {}
}

export class BuildingTypeLoad implements Action {
  readonly type = BuildingTypeActionTypes.SINGLE_LOAD;
  constructor(public payload: string) {}
}

export class BuildingTypeLoaded implements Action {
  readonly type = BuildingTypeActionTypes.SINGLE_LOADED;
  constructor(public payload: BuildingType) {}
}

export class BuildingTypeCreate implements Action {
  readonly type = BuildingTypeActionTypes.CREATE_SEND;
  constructor(public payload: BuildingType) {}
}

export class BuildingTypeCreated implements Action {
  readonly type = BuildingTypeActionTypes.CREATE_DONE;
  constructor(public payload: BuildingType) {}
}

export class BuildingTypeUpdate implements Action {
  readonly type = BuildingTypeActionTypes.UPDATE_SEND;
  constructor(public payload: BuildingType) {}
}

export class BuildingTypeUpdated implements Action {
  readonly type = BuildingTypeActionTypes.UPDATE_DONE;
  constructor(public payload: BuildingType) {}
}

export type BuildingTypeActions =
  BuildingTypesLoad | BuildingTypesLoaded |
  BuildingTypeLoad | BuildingTypeLoaded |
  BuildingTypeCreate | BuildingTypeCreated |
  BuildingTypeUpdate | BuildingTypeUpdated;
