import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ProductState} from '../reducers/product.reducer';


export const selectState = createFeatureSelector('product');

export const selectProducts = (state: ProductState) => state.products;
export const selectProductIsLoading = (state: ProductState) => state.isLoading;

export const getProducts = createSelector(selectState, selectProducts);
export const areProductsLoading = createSelector(selectState, selectProductIsLoading);
