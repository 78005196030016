import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AppContainerComponent} from './components/layouts/app-container/app-container.component';
import {LoginContainerComponent} from './components/layouts/login-container/login-container.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {AuthGuard} from './guards/auth.guard';
import {CustomersComponent} from './components/customers/customers.component';
import {CustomerDetailComponent} from './components/customers/customer-detail/customer-detail.component';
import {SettingsComponent} from './components/settings/settings.component';
import {ProjectDetailComponent} from './components/customers/customer-detail/project-detail/project-detail.component';
import {BuildingTypeDetailComponent} from './components/settings/building-type/building-type-detail/building-type-detail.component';
import {InframeshMountingStyleDetailComponent} from './components/settings/inframesh-mounting-style/inframesh-mounting-style-detail/inframesh-mounting-style-detail.component';
import {ProjectRoomTypeDetailComponent} from './components/settings/project-room-type/project-room-type-detail/project-room-type-detail.component';
import {ProductDetailComponent} from './components/settings/products/product-detail/product-detail.component';
import {UnassignedCustomersComponent} from './components/customers/unassigned-customers/unassigned-customers.component';
import {MyPartnerComponent} from './components/partner/my-partner/my-partner.component';
import {PartnerComponent} from './components/partner/partner.component';
import {UsersComponent} from './components/users/users.component';
import {MyUserComponent} from './components/users/my-user/my-user.component';
import {OffersComponent} from './components/offers/offers.component';
import {ProductOffersComponent} from './components/productoffers/productoffers.component';
import {DownloadsComponent} from './components/downloads/downloads.component';

import {CustomerDetailProductsComponent} from './components/customers/customer-detail/customer-detail-products/customer-detail-products.component';

const routes: Routes = [
  {
    path: '',
    component: AppContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      }, {
        path: 'users',
        component: UsersComponent
      }, {
        path: 'partners',
        component: PartnerComponent
      }, {
        path: 'downloads',
        component: DownloadsComponent
      }, {
        path: 'offers',
        component: OffersComponent
      }, {
        path: 'productoffers',
        component: ProductOffersComponent
      }, {
        path: 'my-user',
        component: MyUserComponent
      }, {
        path: 'my-partner',
        component: MyPartnerComponent
      }, {
        path: 'customers',
        component: CustomersComponent,
        data: {title: 'Kundenübersicht'}
      }, {
        path: 'unassignedCustomers',
        component: UnassignedCustomersComponent,
        data: {title: 'Unzugeordnete Kunden'}
      }, {
        path: 'customers/:id',
        component: CustomerDetailComponent
      }, {
        path: 'settings',
        component: SettingsComponent
      }, {
        path: 'settings/building-type/:id',
        component: BuildingTypeDetailComponent
      }, {
        path: 'settings/project-room-type/:id',
        component: ProjectRoomTypeDetailComponent
      }, {
        path: 'settings/inframes-mounting-styles/:id',
        component: InframeshMountingStyleDetailComponent
      }, {
        path: 'settings/products/:id',
        component: ProductDetailComponent
      }, {
        path: 'projects/:id',
        component: ProjectDetailComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginContainerComponent,
    children: [
      {
        path: '',
        component: LoginComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
