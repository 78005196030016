export class Project {
  id: string;
  name: string;
  customer: string;
  street: string;
  zipCode: string;
  city: string;
  country: string;
  altitude: number;
  buildingType: string;
  hasOwnHeizlast: boolean;
  energycost: number;
  heatingdays: number;
  heatinghours: number;
}
