import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {InframeshMountingStyle} from '../models/inframesh-mounting-style.model';
import {Constants} from '../app.constants';
import {BaseService} from './base.service';

@Injectable({
  providedIn: 'root'
})
export class InframeshMountingStyleService extends BaseService<InframeshMountingStyle> {

  constructor(protected http: HttpClient) {
    super(http, Constants.API_URL_INFRAMESH_MOUNTINGTYPE);
  }
}
